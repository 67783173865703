/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import '../css/app.css'
import Login from '../components/Login'
import { MainContext } from '../contexts/MainContext'
import News from '../components/News'


function NewsPage() {
    const { rootState, setActualPage, isLoggedIn } = useContext(MainContext);
    const { isAuth, showLogin } = rootState;

    useEffect(() => {       // runs function only once
        setActualPage('news')
        isLoggedIn()
    }, [])

    // If user Logged in
    if (isAuth) {
        return (
            <div className="page_container">
            
                <News />

            </div>
        )
    } else if (showLogin) {
        return <Login login_bg='true' />;
    }
}

export default NewsPage;