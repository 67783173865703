/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/PortfolioOverview.css'
import PrevChart from './PrevChart'
import WithdrawAccount from './WithdrawAccount'
import CircleProgressBar from './CircleProgressBar'
import Loading from './Loading'

function PortfolioOverview(){
    const { userAccountData, getTableWhereApi, userData, updateUi, getTimestemp } = useContext(MainContext);

    const [transactionData, setTransactionData] = useState({
        data: [],
        history: [],
        dates: [] 
    })

    useEffect(() => {       // wait for userData to be loaded than run function
        if(userData.loaded !== false){
            getTransactionData()
        }
    }, [userData, updateUi, userAccountData])

    const [showWithdrawAccount, setShowWithdrawAccount] = useState(false)

    const handleWithdrawAccount = () => {
        setShowWithdrawAccount(true)
    }

    const handleWithdrawCallBack = () => {
        setShowWithdrawAccount(false)
    }

    const getTransactionData = async () => {
        let requestTransationData = await getTableWhereApi('transactions', 'user_id', userData.id, 'int')
        let valArray = [0]
        let datesArray = [getTimestemp().split("_")[0]]

        if( requestTransationData && requestTransationData.hasOwnProperty('success') && requestTransationData.success === 0){
            // if has no transaction data return [0,0] -> flat line
            valArray.push(0)
        } else {                    
            requestTransationData.forEach((e) => {
                valArray.push(parseInt(e.amount))
                datesArray.push((e.timestemp).split("_")[0])
            })
            valArray.push(0)
            datesArray.push("0")
            valArray = valArray.reverse()
            datesArray = datesArray.reverse()
        }

        setTransactionData({
            data: requestTransationData,
            history: valArray,
            dates: datesArray
        })
    }

    // console.log(userAccountData)

    let graphValueInvested = Math.round(userAccountData.locked_amount / userAccountData.amount * 100)
    let graphValueAvalible = 100 - Math.round(userAccountData.locked_amount / userAccountData.amount * 100)

    if(!userAccountData.amount){
        graphValueInvested = 0
        graphValueAvalible = 0
    }

    if (!userAccountData){
        return(
            <Loading />
        )
    }

    return(
        <div className="container content_box component_cb portfolio_overiview">
            <p className="cb_label_right">Konto</p>

            <div className="portfolio_overiview_conainer">
                <h2 className="acount_balance">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(userAccountData.amount) }</h2>

                <div className="po_grid">

                    <div className="circular_progressbar_box">
                        <div className="circular_progressbar">
                            { window.innerWidth < 1400 ?
                                <CircleProgressBar data={graphValueInvested} type="small" gradient={1} />
                                : <CircleProgressBar data={graphValueInvested} gradient={1} />
                            }
                        </div>
                        <div className="textbox">
                            <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(userAccountData.locked_amount) }</p>
                            <p className="amount_label">v investicích</p>
                        </div>
                    </div>

                    <div className="circular_progressbar_box">
                        <div className="circular_progressbar">
                            {window.innerWidth < 1400 ?
                                <CircleProgressBar data={graphValueAvalible} type="small" gradient={2} />
                                : <CircleProgressBar data={graphValueAvalible} gradient={2} />
                            }
                        </div>
                        <div className="textbox">
                            <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(userAccountData.amount - userAccountData.locked_amount) }</p>
                            <p className="amount_label">k dispozici</p>
                        </div>
                    </div>

                    <PrevChart data={transactionData}/>

                </div>

                <div className="transaction_history_previw_wrapper">
                    <div className="thp_line line_legend">
                        <p>datum</p>
                        <p>transakce</p>
                        <p>změna</p>
                        <p>částka</p>
                    </div>
                    {/* this is just if() -> transactionData.data.length > 0 && ... */}
                    { transactionData.data.length > 0 && transactionData.data.slice(0, 3).map((data, i) => {
                        return (
                            <div className="thp_line" key={i}>
                                <p>{data.timestemp.split('_')[0]}</p>
                                <p>{data.description}</p>
                                <p className={`${data.acount_move >= 0 ? 'move_up' : 'move_down'} `}>{data.acount_move + '%'}</p>
                                <p className="amount currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(data.amount)}</p>
                            </div>
                        )
                    
                    })}
                </div>

                <button className="button button--small withdraw_account_btn" onClick={handleWithdrawAccount} >
                    Vybrat konto
                </button>

                {showWithdrawAccount && <WithdrawAccount callBack={handleWithdrawCallBack}/> }

            </div>
        </div>
    )
}

export default PortfolioOverview
