/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/CreateInvestment.css'
import CircleProgressBar from './CircleProgressBar';

function CreateInvestmentConfig(props){
    const { calculateInterest, userAccountData, setSystemNotif, agreementPdfLink } = useContext(MainContext);

    const [circleProgressBarContent, setCircleProgressBarContent] = useState(
        <div className="circular_progressbar">
            <CircleProgressBar data={0} />
        </div>
    )

    const [investmentDataParam, setInvestmentDataParam] = useState({
        amount: '',
        interest: 6,
        duration: '2 roky',
        duration_int: 2,
        investment_type: props.investmentType,
    })

    const [interestCalc, setInterestCalc] = useState({
        monthly_profit: 0,
        full_profit: 0
    })

    const [generatePdfInProgress, setGeneratePdfInProgress] = useState(false)

    useEffect(() => {
        if (props.investmentType === 2){
            setInvestmentDataParam({
                ...investmentDataParam,
                interest: 8,
                duration: '4 roky',
                duration_int: 4,
                investment_type: props.investmentType,
            })
        }
    }, [])

    useEffect(() => {
        props.callBack(investmentDataParam)
    }, [investmentDataParam])

    const updateContent = (e) => {
        let value = investmentDataParam.amount
        value = e.target.value
        if (e.target.value === ""){
            value = "0"
        }
        if (!Number(e.target.value)){
            return
        }
        setInvestmentDataParam({
            ...investmentDataParam,
            amount: parseInt(value)
        })
        updateData(parseInt(value))

        let accountBalance = userAccountData.amount - userAccountData.locked_amount
        let investProcentage = Math.round((value / accountBalance) * 100)
        if (value > accountBalance){
            setSystemNotif({
                error: <div className="notification notification--error">chyba zadaná částka překračuje dostupnou částku</div>
            })
        } else if (value < 20000 && userAccountData.currency === "czk"){
            setSystemNotif({
                error: <div className="notification notification--error">minimální částka je 20 000 Kč</div>
            })
        } else if (value < 1000 && userAccountData.currency === "eur"){
            setSystemNotif({
                error: <div className="notification notification--error">minimální částka je 1 000 €</div>
            })
        } else {
            setSystemNotif({ error: '' })
        }
        setCircleProgressBarContent(
            <div className="circular_progressbar">
                <CircleProgressBar data={investProcentage} />
            </div>
        )
    }

    const updateData = (amount) => {
        let amount_with_interest = calculateInterest(amount, investmentDataParam.duration_int, investmentDataParam.interest, 2)
        setInterestCalc({
            monthly_profit: amount_with_interest / (12 * investmentDataParam.duration_int),
            full_profit: amount_with_interest,
        })

        let accountBalance = userAccountData.amount - userAccountData.locked_amount
        if (!generatePdfInProgress && investmentDataParam.amount < accountBalance && investmentDataParam.amount >= 100){
            setGeneratePdfInProgress(true)
            console.log("> generatePdfTimeout")
            setTimeout(function () {
                props.generatePdf(amount)
                setGeneratePdfInProgress(false)
            }, 800)
        }

    }

    return (
        <div className="investment_params_wrapper">
            <div className="investment_params_box">
                <div className="text_edit_item mid_size_input invest_input">
                    <label className="input_label">Investovaná částka</label>
                    <div className="input curency">
                        <input type="text" value={investmentDataParam.amount} placeholder={userAccountData.currency === "czk" ? "částka Kč" : "částka " + userAccountData.currency} onChange={e => updateContent(e)} />
                    </div>
                </div>

                <div className="text_edit_item hlaf_input">
                    <label className="input_label">Úrok</label>
                    <div className="input inactive">
                        <input type="text" readOnly value={investmentDataParam.interest} placeholder="úrok" />
                    </div>
                </div>

                <div className="text_edit_item hlaf_input sec">
                    <label className="input_label">Délka investice</label>
                    <div className="input inactive">
                        <input type="text" readOnly value={investmentDataParam.duration} placeholder="roky" />
                    </div>
                </div>

                <div className="text_edit_item hlaf_input">
                    <label className="input_label">Měsíční výnos</label>
                    <div className="input inactive curency">
                        <input type="text" readOnly value={new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(interestCalc.monthly_profit)} placeholder="0 kč" />
                    </div>
                </div>

                <div className="text_edit_item hlaf_input sec">
                    <label className="input_label">Celkový výnos</label>
                    <div className="input inactive curency">
                        <input type="text" readOnly value={new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(interestCalc.full_profit)} placeholder="0 kč" />
                    </div>
                </div>
            </div>

            <div className="circular_progressbar_box">
                <div className="textbox">
                    <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(userAccountData.amount - userAccountData.locked_amount)}</p>
                    <p className="amount_label">k dispozici</p>
                </div>
                {circleProgressBarContent}
            </div>

            {agreementPdfLink ?
                <a href={agreementPdfLink} target="_blank" rel="noreferrer"><button className="button button--outlined button--small agreement_invest">Smlouva</button></a>
                : <button className="button button--outlined button--small agreement_invest inactive">Smlouva</button>}

            {agreementPdfLink ?
                <button onClick={props.handleConfirm} className="button button--small continue_invest_sign">Podepsat</button>
                : <button className="button button--small continue_invest_sign inactive">Podepsat</button>}


        </div>
    )

}

export default CreateInvestmentConfig