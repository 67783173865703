/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/Settings.css'
import IdentityDocuments from './IdentityDocuments'
import UserDocuments from './UserDocuments'
import SwitchUI from './SwitchUI'
import FileUploadForm from './FileUploadForm'
import { Link } from 'react-router-dom'
import Loading from './Loading'
// import { Prompt } from 'react-router'

function Settings(){
    const { rootState, userData, pageApiUrl, getTableWhereApi, postTableApi, setSystemNotif, 
        updateUserData, mailApi, getTimestemp, getError, getTableApi, systemNotifMail } = useContext(MainContext);
    const { theUser } = rootState;

    const [userStatus, setUserStatus] = useState({
        status: 'neznámý',
        class: '',
        statusCode: 0
    })

    const [isLoading, setIsLoading] = useState(false)

    const [userDataEdit, setUserDataEdit] = useState({
        id: null,
        name: '',
        surname: '',
        parent: 0,
        email: '',
        phone: '',
        bank_account: '',
        birth_number: '',
        ico: '',
        dic: '',
        primary_address: {street: '', city: '', psc: '', state: ''},
        secondary_address: {street: '', city: '', psc: '', state: ''},
        identity_card: {valid: '', number: '', provider: ''},
        driving_license: {valid: '', number: '', provider: ''},
        profile_picture: '',
        funds_in_rs: 0
    })
    const [unsaved, setUnsaved] = useState(false)
    const [fundsInRsSwitch, setFundsInRsSwitch] = useState(true)
    const [showSecondaryAddress, setShowSecondaryAddress] = useState(false)
    const [parentData, setParentData] = useState({
        id: 0,
        name: 'admin',
        surname: '',
    })

    useEffect(() => {       // wait for userData to be loaded than run function
        if(userData.loaded !== false){

            console.log(userData)
            
            getUserStatus()
            getParentData()

            initUserData(userData)
        }
    }, [userData])

    const getUserStatus = () => {
        let statusString = ''
        let statusClass = ''
        let statusCode = parseInt(theUser.status)

        if(statusCode === 1){
            statusString = 'v pořádku'
            statusClass = 'active_user'
        } else if(statusCode === 2){
            statusString = 'zablokovaný'
            statusClass = 'error'
        } else if(statusCode === 0){
            statusString = 'neaktivní'
            statusClass = 'error'
        }

        setUserStatus({
            status: statusString,
            class: statusClass,
            statusCode: statusCode
        })
    }

    const checkParam = (key, value) => {
        return (value === null) ? "" : value
    }

    const initUserData = (userData) => {
        // this is just to set emty object instad of null from db
        let keyArray = ['primary_address', 'secondary_address', 'identity_card', 'driving_license']
        let valArray = [{street: '', city: '', psc: '', state: ''}, {valid: '', number: '', provider: ''}]
        let setDataObject = { ...userData }
        for(let i = 0; i < keyArray.length; i++){
            let data = JSON.parse(userData[keyArray[i]])
            if (!userData[keyArray[i]]){
                // whole number division 0 1 -> 0 | 2 3 -> 1 in valArray
                data = valArray[Math.floor(i/2)]
            }
            setDataObject[keyArray[i]] = data
        }
        // replace null with empty strings
        Object.keys(setDataObject).forEach(key => {
            setDataObject[key] = checkParam(key, setDataObject[key])
        })
        
        setUserDataEdit(setDataObject)

        setShowSecondaryAddress(userData.secondary_address === "" 
        || !userData.secondary_address
        || userDataEdit.secondary_address.street === ''
        || userDataEdit.secondary_address.city === '' ? false : true)

        console.log("inituserData...")
        console.log(userData)
        console.log(userData.funds_in_rs)
        setFundsInRsSwitch(userData.funds_in_rs === 1 || userData.funds_in_rs === "1" ? true : false)
        // setFundsInRsSwitch(false)       // DEBUG ONLY!! 

    }

    const getParentData = async () => {
        let requestParentData = await getTableWhereApi('user_data', 'id', userData.parent, 'int')
        if( requestParentData && requestParentData.hasOwnProperty('success') && requestParentData.success === 0){
            console.warn("Parent does not exist id: " + userData.parent)
            return
        }
        if(requestParentData[0].surname === null){
            requestParentData[0].surname = ''
        }
        setParentData(requestParentData[0])
    }

    const handelCallBack = (status) => {
        if(status.error){
            setUserStatus({
                status: status.string,
                class: 'error',
                statusCode: 3
            })
        } else {
            getUserStatus()
        }
    }

    // ----- user data section ----- //

    const updateContent = (e, key) => {
        let data = userDataEdit[key];
        data = e.target.value;
        setUnsaved(true)
        setUserDataEdit({
            ...userDataEdit,
            [key]: data
        })
    };

    const updateContentSwitch = (e, key) => {
        let data = userDataEdit[key]
        if(userDataEdit[key] === "1"){
            data = "0" 
        } else if(userDataEdit[key] === "0"){
            data = "1" 
        }
        setUnsaved(true)
        setUserDataEdit({
            ...userDataEdit,
            [key]: data
        })

        setFundsInRsSwitch(!fundsInRsSwitch)

        console.log(">> sw update", data)
    };

    const updateContentDoubleKey = (e, key) => {
        let data = userDataEdit[key[0]][key[1]];
        data = e.target.value;
        setUnsaved(true)
        setUserDataEdit({
            ...userDataEdit,
            [key[0]]:{
                ...userDataEdit[key[0]],
                [key[1]]: data
            }
        })
    };

    const handleShowSecAddress = () => {
        setShowSecondaryAddress(!showSecondaryAddress)
    }

    const uploadCallBack = (response) => {
        if(response.error){
            setSystemNotif({
                error: <div className="notification notification--error">Nebyl vybrán soubor, nebo nastala chyba</div>
            })
            setTimeout(function(){
                setSystemNotif({ error: '' })  
            }, 5000)
            return
        }

        setUserDataEdit({
            ...userDataEdit,
            profile_picture: response.response.url
        })
        setUnsaved(true)
    }

    const getAdminData = async () => {
        let getAdminIds = await getTableWhereApi("users", "user_type", "admin", "string")
        let adminIdArray = []

        if (getAdminIds && getAdminIds.hasOwnProperty('success') && getAdminIds.success === 0) {
            return null
        }
        getAdminIds.forEach((e) => {
            adminIdArray.push(e.id)
        })

        let gaeUserData = await getTableApi("user_data")
        let adminData = []

        if (gaeUserData && gaeUserData.hasOwnProperty('success') && gaeUserData.success === 0) {
            return null
        }
        gaeUserData.forEach((e) => {
            if (adminIdArray.includes(e.id))
                adminData.push(e)
        })
        return adminData
    }

    const sendMailNotifications = async (data) => {
        let adminData = await getAdminData()
        if (!adminData) {
            getError("došlo k chybě")
            return
        }

        systemNotifMail('Klient změnil typ vyplácení:<br><br>id klienta: ' + userData.id +
            '<br>jméno: ' + userData.name + ' ' + userData.surname + '<br><br>způsob vyplácení: ' + (data.funds_in_rs === true ? 'na RS účet v systému' : 'na bankovní účet') +
            '<br><br>číslo účtu: ' + userData.bank_account +
            '<br>email klienta: ' + userData.email + '<br>datum: ' + getTimestemp())

        // send notification to user
        let mailData = {
            mail_to: userData.email,
            subject: 'Future Invest - Změna nastavení účtu',
            message: ('Na vašem účtě proběhla změna:<br><br>způsob vyplácení úroků: ' + (data.funds_in_rs === true ? 'na Future Invest účet v systému' : 'na bankovní účet') +
                '<br><br>datum: ' + getTimestemp())
        }
        let mailResponse = await mailApi(mailData)
        if (mailResponse.Messages[0].Status !== 'success') {
            console.warn("[ error ] --> userMail ", mailResponse)
            return false
        }
        return true
    }

    const updateTable = async () => {

        try{

            setIsLoading(true)
            let userDataEditRequest = userDataEdit
            userDataEditRequest.primary_address = JSON.stringify(userDataEdit.primary_address)
            userDataEditRequest.secondary_address = JSON.stringify(userDataEdit.secondary_address)
            userDataEditRequest.identity_card = JSON.stringify(userDataEdit.identity_card)
            userDataEditRequest.driving_license = JSON.stringify(userDataEdit.driving_license)

            // check if fund in rs changed
            console.log(userDataEdit.funds_in_rs)
            if (userDataEdit.funds_in_rs !== userData.funds_in_rs) {
                console.log(">> Updated funds in RS >> notify")
                let resp = sendMailNotifications({ funds_in_rs: userDataEdit.funds_in_rs === 1 ? true : false })
                if (!resp) {
                    setIsLoading(false)
                    return
                }
            }

            let gatPrevUserData = await getTableWhereApi("user_data", "id", userData.id, "int")
            let changes = []

            if (Array.isArray(gatPrevUserData) && gatPrevUserData.length >= 1) {
                for (const [key, value] of Object.entries(gatPrevUserData[0])) {
                    if (userDataEdit[key] !== value) {
                        changes.push({
                            key: key,
                            value: userDataEdit[key],
                            prev: value
                        })
                    }
                }

                let notifMessage = 'Změna nastavení účtu klienta<br>id klienta: ' +
                    userData.id + '<br>jméno: ' + userData.name + ' ' + userData.surname + '<br>------------------------------<br>'

                changes.forEach(item => {
                    if (item.key === 'primary_address' || item.key === 'secondary_address') {
                        let address = JSON.parse(item.value)
                        let addressPrev = JSON.parse(item.prev)

                        notifMessage += "adresa: " + item.key + '<br>' +
                            'ulice: ' + addressPrev?.street + ' -> ' + address?.street + '<br>' +
                            'město: ' + addressPrev?.city + ' -> ' + address?.city + '<br>' +
                            'psč: ' + addressPrev?.psc + ' -> ' + address?.psc + '<br>' +
                            'stát: ' + addressPrev?.state + ' -> ' + address?.state + '<br>'
                    } else if (item.key !== 'profile_picture') {
                        notifMessage += item.key + ': ' + item.prev + ' -> ' + item.value + '<br>'
                    }
                })
                notifMessage += '<br>------------------------------<br>'

                systemNotifMail(notifMessage)
            }

            let tableObj = {
                data: [userDataEdit],
                changes: [0],
                post_table: 'user_data'
            }
            let response = await postTableApi(tableObj)

            if (response && response.hasOwnProperty('success') && response.success === 0) {
                setSystemNotif({
                    error: <div className="notification notification--error">Nastala chyba při ukládání</div>
                })
                setTimeout(function () {
                    setSystemNotif({ error: '' })
                }, 5000)
                setIsLoading(false)
                return
            }

            setTimeout(async function () {
                updateUserData()
                initUserData(userData)
                setUnsaved(false)

                setSystemNotif({
                    success: <div className="notification notification--success">uloženo</div>
                })
                setTimeout(function () {
                    setSystemNotif({ success: '' })
                }, 3000)
            }, 500)
            setIsLoading(false)
            
        } catch(Exception){
            console.error(Exception)
            setIsLoading(false)
            getError("došlo k chybě (update exception)")
        }
    }

    if((userData.hasOwnProperty('loaded') && !userData.loaded) || isLoading){
        return (
            <Loading />
        )
    }

    return (<>
{/* 
        <Prompt
            when={unsaved}
            message='Máte neuložené změny, opravdu chcete opustit stránku?'
        /> */}
    
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage settings_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Nastavení účtu</h3>
                    <p className="cb_fullpage_sublabel_right">Nastavení Vašeho profilu a informací</p>
                </div>

                <div className="fullpage_component_content_wrapper">
                    <p className="user_status">Status <span className={`user_status_inner ${ userStatus.class }`}>{ userStatus.status }</span></p>

                    <div className="settings_grid">
                        <div className="container content_box component_cb grid_box">
                            <p className="cb_label_left">Doklady</p>
                            <IdentityDocuments edit={true} callBack={handelCallBack}/>
                        </div>

                        <div className="container content_box component_cb grid_box">
                            <p className="cb_label_left">Dokumenty</p>
                            <UserDocuments/>
                        </div>

                        <div className="input_container grid_box">
                            <p className="cb_label_left">Informace</p>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">Jméno</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.name} placeholder="Jméno" onChange={e => updateContent(e, 'name')} />
                                </div>
                            </div>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">Příjmení</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.surname} placeholder="Příjmení" onChange={e => updateContent(e, 'surname')} />
                                </div>
                            </div>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">Telefonní číslo</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.phone} placeholder="+420 000000000" onChange={e => updateContent(e, 'phone')} />
                                </div>
                            </div>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">IČO</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.ico} placeholder="000" onChange={e => updateContent(e, 'ico')} />
                                </div>
                            </div>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">Rodné číslo</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.birth_number} placeholder="000000/0000" onChange={e => updateContent(e, 'birth_number')} />
                                </div>
                            </div>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">DIČ</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.dic} placeholder="CZ000" onChange={e => updateContent(e, 'dic')} />
                                </div>
                            </div>
                        </div>

                        <div className="container content_box component_cb input_container grid_box">
                            <p className="cb_label_left">Nastavení</p>
                            <div className="input_container_wrapper">
                                {/* <SwitchUI text="Nechávat vyplacený úrok v na Revevolution Strategy účtu" uid="1" className="in_box_switch" defaultChecked={fundsInRsSwitch} 
                                    onChange={e => updateContentSwitch(e, 'funds_in_rs')} /> */}

                                <div className="switch_container">
                                    <p>Nechávat vyplacený úrok na investičním účtu Future Invest</p>
                                    <input type="checkbox" id="switch_ui 1" className="switch_ui" checked={fundsInRsSwitch} onChange={e => updateContentSwitch(e, 'funds_in_rs')} />
                                    <label htmlFor="switch_ui 1" className="switch_ui_label in_box_switch"></label>
                                </div>

                                <div className="text_edit_item mid_size_input">
                                    <label className="input_label">Číslo bankovního účtu</label>
                                    <div className="input input-fullWidth">
                                        <input type="text" value={userDataEdit.bank_account} placeholder="00000000000/0000" onChange={e => updateContent(e, 'bank_account')} />
                                    </div>
                                </div>

                                <div className="text_edit_item mid_size_input">
                                    <label className="input_label">e-mail</label>
                                    <div className="input input-fullWidth">
                                        <input type="text" value={userDataEdit.email} placeholder="email" onChange={e => updateContent(e, 'email')} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="input_container grid_box">
                            <p className="cb_label_left">Adresa trvalého bydliště</p>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">Ulice a č.p.</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.primary_address.street} placeholder="Ulice a č.p." onChange={e => updateContentDoubleKey(e, ['primary_address', 'street'])} />
                                </div>
                            </div>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">Město</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.primary_address.city} placeholder="Město" onChange={e => updateContentDoubleKey(e, ['primary_address', 'city'])} />
                                </div>
                            </div>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">Stát</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.primary_address.state} placeholder="Stát" onChange={e => updateContentDoubleKey(e, ['primary_address', 'state'])} />
                                </div>
                            </div>

                            <div className="text_edit_item hlaf_input">
                                <label className="input_label">PSČ</label>
                                <div className="input input">
                                    <input type="text" value={userDataEdit.primary_address.psc} placeholder="000 00" onChange={e => updateContentDoubleKey(e, ['primary_address', 'psc'])} />
                                </div>
                            </div>

                            <SwitchUI text="Zadat jinou korespondenční adresu" uid="2" className="outer_switch" checked={showSecondaryAddress}
                                onChange={handleShowSecAddress} />
                            
                            <div className="padding_line"></div>

                            {showSecondaryAddress && <>
                                <div className="text_edit_item hlaf_input">
                                    <label className="input_label">Ulice a č.p.</label>
                                    <div className="input input">
                                        <input type="text" value={userDataEdit.secondary_address.street} placeholder="Ulice a č.p." onChange={e => updateContentDoubleKey(e, ['secondary_address', 'street'])} />
                                    </div>
                                </div>

                                <div className="text_edit_item hlaf_input">
                                    <label className="input_label">Město</label>
                                    <div className="input input">
                                        <input type="text" value={userDataEdit.secondary_address.city} placeholder="Město" onChange={e => updateContentDoubleKey(e, ['secondary_address', 'city'])} />
                                    </div>
                                </div>

                                <div className="text_edit_item hlaf_input">
                                    <label className="input_label">Stát</label>
                                    <div className="input input">
                                        <input type="text" value={userDataEdit.secondary_address.state} placeholder="Stát" onChange={e => updateContentDoubleKey(e, ['secondary_address', 'state'])} />
                                    </div>
                                </div>

                                <div className="text_edit_item hlaf_input">
                                    <label className="input_label">PSČ</label>
                                    <div className="input input">
                                        <input type="text" value={userDataEdit.secondary_address.psc} placeholder="000 00" onChange={e => updateContentDoubleKey(e, ['secondary_address', 'psc'])} />
                                    </div>
                                </div> 
                            </>}
                        </div>



                        <div className="input_container grid_box">
                            <p className="cb_label_left">Dodatečné informace</p>
                            
                            <p className="cb_label_left_profile_pic">Profilová fotka</p>
                            <div className="user_profile_pic">

                                { userDataEdit.profile_picture !== '' && userDataEdit.profile_picture ?
                                    <img src={pageApiUrl + 'uploads/' + userDataEdit.profile_picture } className="profile_img" alt="profile_img"/>
                                    : <div className="empty_img"></div>
                                }
                                <FileUploadForm className="profioe_pic_upload" autoupload={true} uid='1' callBack={uploadCallBack} type='frotImg' />

                            </div>

                            <div className='container content_box cb-small-short small_contextbox'>
                                <div className="action_context_box_wrpaper">
                                    <p className="label">Změna hesla účtu</p>
                                    <Link to="/change_pass" ><button className="button button--small button--outlined change_pass_btn">Změnit</button></Link>
                                </div>
                            </div>

                            <div className="container content_box component_cb">
                                <p className="cb_label_left">Váš investiční zástupce</p>
                                <div className="input_container_wrapper">

                                <div className="user_info">
                                    { parentData.profile_picture !== '' && parentData.profile_picture ?
                                            <img src={pageApiUrl + 'uploads/' + parentData.profile_picture } className="profile_img" alt="profile_img"/>
                                        : <div className="empty_img"></div>
                                    }
                                    <p>{parentData.name + ' ' + parentData.surname}</p>
                                </div>

                                    <a href={'mailto: ' + parentData.email}><button className="button button--small contact_btn">Kontaktovat</button></a>
                                </div>
                            </div>

                        </div>

                        <div className={`container content_box cb-small-short action_box_container ${unsaved && 'highlight_box'}`}>
                            <div className="action_box_wrpaper">
                                <p className="label">{unsaved ? 'Uložit změny provedené na webu ' : 'Nebyly provedeny žádné změny '} </p>
                                <button onClick={updateTable} className="button button--small update_btn">Uložit</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </>
    )
}

export default Settings


// unused code OP check... 

    // <div className="input_container grid_box">
    //     <p className="cb_label_left">Občanský průkaz</p>

    //     <div className="text_edit_item hlaf_input">
    //         <label className="input_label">Číslo průkazu</label>
    //         <div className="input input">
    //             <input type="text" value={userDataEdit.identity_card.number} placeholder="000000000" onChange={e => updateContentDoubleKey(e, ['identity_card', 'number'])} />
    //         </div>
    //     </div>

    //     <div className="text_edit_item hlaf_input">
    //         <label className="input_label">Platnost průkazu</label>
    //         <div className="input input">
    //             <input type="text" value={userDataEdit.identity_card.valid} placeholder="1.12.2020" onChange={e => updateContentDoubleKey(e, ['identity_card', 'valid'])} />
    //         </div>
    //     </div>

    //     <div className="text_edit_item hlaf_input">
    //         <label className="input_label">průkaz vydal</label>
    //         <div className="input input">
    //             <input type="text" value={userDataEdit.identity_card.provider} placeholder="MěÚ Praha" onChange={e => updateContentDoubleKey(e, ['identity_card', 'provider'])} />
    //         </div>
    //     </div>
    //     <div className="padding_line"></div>

    //     <p className="cb_label_left">Řidičský průkaz / Karta pojištěnce</p>

    //     <div className="text_edit_item hlaf_input">
    //         <label className="input_label">Číslo průkazu</label>
    //         <div className="input input">
    //             <input type="text" value={userDataEdit.driving_license.number} placeholder="000000000" onChange={e => updateContentDoubleKey(e, ['driving_license', 'number'])} />
    //         </div>
    //     </div>

    //     <div className="text_edit_item hlaf_input">
    //         <label className="input_label">Platnost průkazu</label>
    //         <div className="input input">
    //             <input type="text" value={userDataEdit.driving_license.valid} placeholder="1.12.2020" onChange={e => updateContentDoubleKey(e, ['driving_license', 'valid'])} />
    //         </div>
    //     </div>

    //     <div className="text_edit_item hlaf_input">
    //         <label className="input_label">průkaz vydal</label>
    //         <div className="input input">
    //             <input type="text" value={userDataEdit.driving_license.provider} placeholder="MěÚ Praha" onChange={e => updateContentDoubleKey(e, ['driving_license', 'provider'])} />
    //         </div>
    //     </div>
    //     <div className="padding_line"></div>
    // </div> 
                        