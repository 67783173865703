import React, { useState } from 'react'
import CreateInvestment from './CreateInvestment';
import './css/NewInvestmentBox.css'
import '../css/app.css'

function NewInvestmentBox(){

    const [showCreateInvestment, setShowInvestment] = useState({
        show: false,
        type: 1
    })

    const handleCloseCallBack = () => {
        setShowInvestment({show: false, type: 1})
    }

    const handleCreateInvestment = (type) => {
        setShowInvestment({ show: true, type: type })
    }

    return(
        <div className="container content_box component_cb">
            <p className="cb_label_right">Investiční plány</p>

            <div className="investment_box_grid">
                <div className="ivn_container container content_box">
                    <p>Investiční plán 1</p>
                    <h3>Investice na 2 roky</h3>
                    <p>od 20 000 Kč, <br/>roční úrok</p>
                    <p className="bg_tx">1</p>
                    <h1 className="interest gradient_text">6%</h1>
                    <button onClick={() => handleCreateInvestment(1)} className="button button--small">Investovat</button>
                </div>
                <div className="ivn_container container content_box">
                    <p>Investiční plán 2</p>
                    <h3>Investice na 4 roky</h3>
                    <p>od 20 000 Kč, <br/>roční úrok</p>
                    <p className="bg_tx">2</p>
                    <h1 className="interest gradient_text">8%</h1>
                    <button onClick={() => handleCreateInvestment(2)} className="button button--small">Investovat</button>
                </div>
            </div>

            { showCreateInvestment.show && 
                <CreateInvestment type={showCreateInvestment.type} callBack={handleCloseCallBack} />
            }

        </div>
    )
}

export default NewInvestmentBox
