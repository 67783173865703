import jsPDF from "jspdf"    
import "jspdf-autotable"    
import Axios from 'axios'    
import '../../assets/Roboto-Regular-normal.js'

async function uploadFile(file, name, pageApiUrl) {

    const formData = new FormData()    
    formData.append('pdf', file, name + '.pdf')

    console.log(pageApiUrl)

    // const UPLOAD_ENDPOINT = "http://earc.xyz/web-project-rs/api/" + 'upload-pdf.php'
    //const UPLOAD_ENDPOINT = 'https://api.futureinvest.cz/' + 'upload-pdf.php'
    const UPLOAD_ENDPOINT = pageApiUrl + 'upload-pdf.php'

    return await Axios.post(UPLOAD_ENDPOINT, formData, {
        headers: {
            'content-type': 'application/pdf'
        }
    })    
}

/*let userData = {
    doc_id: '10236',
    name: 'Jan Novák',
    birth_number: '010532/5343',
    address: 'Na Průhonu 17125, 321 00 Plzeň 21',
    con_address: 'Na Průhonu 17125, 321 00 Plzeň 21',
    tel: '370 253 874',
    email: 'novak.jan@seznam.cz',
    bank_account: '43-74839348244/5540',
    amount: '65 000',
    amount_words: 'šedesát pět tisíc',
    interest: '8',
    duration: '4',
    sms_code: '543554'
}*/

const generatePDF = async (user, pageApiUrl, currency) => {

    console.log(pageApiUrl)
    
    let doc_id = user.doc_id

    var lMargin = 15     //left margin in mm
    var rMargin = 15     //right margin in mm
    var pdfInMM = 210      // width of A4 in mm

    let image = new Image()    
    image.src = 'logo.png'    

    let signature = new Image()    
    signature.src = 'signature.png'    

    const doc = new jsPDF("p","mm","a4") 
    doc.setFont('Roboto-Regular', 'normal')

    doc.setFontSize(25)
    doc.addImage(image, 'PNG', 12, 9, 36, 20)

    doc.text(40, 40, 'Smlouva na dobu určitou číslo ' + doc_id)
    doc.setFontSize(12)

    let paragraph = 'TATO SMLOUVA O ZÁPŮJČCE (dále jen „Smlouva“) BYLA UZAVŘENA NÍŽE UVEDENÉHO DNE, MĚSÍCE A ROKU MEZI TĚMITO SMLUVNÍMI STRANAMI'
    let lines = doc.splitTextToSize(paragraph, (pdfInMM-lMargin-rMargin))    
	doc.text(lMargin, 58, lines)    

    doc.setFontSize(18)
    doc.setTextColor(94, 112, 104)
	doc.text(lMargin, 80, 'Zapůjčitel', { charSpace: '0.3' })    
    doc.setFontSize(12)
    doc.setTextColor(0)
	doc.text(lMargin, 90, 'Jméno Příjmení: ' + user.name)    
	doc.text(lMargin, 100, 'Rodné číslo: ' + user.birth_number)    
	doc.text(lMargin, 110, 'Trvalé bydliště: ' + user.address)     
	doc.text(lMargin, 120, 'Korespondenční adresa: ' + user.con_address)
	doc.text(lMargin, 130, 'Telefon: ' + user.tel)
	doc.text(lMargin, 140, 'Email: ' + user.email)
	doc.text(lMargin, 150, 'Komunikační účet: ' + user.bank_account)
	doc.text(lMargin, 160, '(dále jako „Zapůjčitel“ nebo „Klient“)')

    doc.setFontSize(18)
    doc.setTextColor(94, 112, 104)
	doc.text(lMargin, 180, 'Vydlužitel', { charSpace: '0.3' })    
    doc.setFontSize(12)
    doc.setTextColor(0)
	doc.text(lMargin, 190, 'UP Future, s.r.o.')    
    doc.text(lMargin, 200, 'IČ: 08841608')    
    doc.text(lMargin, 210, 'Adresa: Korunní 2569/108, Vinohrady (Praha 10), 101 00 Praha')    
	doc.text(lMargin, 220, 'Email: info@upinvest.cz')
	doc.text(lMargin, 230, 'Za kterou jednají: Oliver Tomm')
	doc.text(lMargin, 240, 'Kontaktní adresa: Tylova 1016, 542 32 Úpice')
	doc.text(lMargin, 250, '(dále jako „Vydlužitel“ nebo „Společnost“)')

    doc.setTextColor(100)
	doc.text(lMargin, 280, 'UP Future, s.r.o.')
    doc.text(lMargin, 285, 'Korunní 2569/108, Vinohrady (Praha 10)')
    doc.text(lMargin, 290, '101 00 Praha')
	doc.text(120, 285, 'info@futureinvest.cz')

    doc.addPage()

    doc.addImage(image, 'PNG', 12, 9, 36, 20)

    doc.setFontSize(10)
    doc.text(lMargin, 35, '(Zapůjčitel a Vydlužitel dále též společně jako „Smluvní strany“ a každý jednotlivě jako „Smluvní strana“)')
    doc.setFontSize(12)
    doc.text(lMargin, 45, 'SMLUVNÍ STRANY UJEDNÁVAJÍ NÁSLEDUJÍCÍ:')

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 60, '1)  Předmět smlouvy')
    doc.setTextColor(0)
    
    let paragraph_01 = 'Klient tímto zapůjčuje Společnosti částku ve výši ' + user.amount + ' Kč (slovy: ' + user.amount_words + ') (dále jako „Zápůjčka“) a Společnost Zápůjčku přijímá a zavazuje se Klientovi Zápůjčku vrátit, a to vše za podmínek této Smlouvy. '
    if (currency === "eur"){
        paragraph_01 = 'Klient tímto zapůjčuje Společnosti částku ve výši ' + user.amount + ' € (slovy: ' + user.amount_words + ') (dále jako „Zápůjčka“) a Společnost Zápůjčku přijímá a zavazuje se Klientovi Zápůjčku vrátit, a to vše za podmínek této Smlouvy. '
    }
	doc.text(lMargin + 8, 65, 'a)  ')
    doc.text(lMargin + 15, 65, doc.splitTextToSize(paragraph_01, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 85, '2)  Předání Zápůjčky')
    doc.setTextColor(0)
    let paragraph_02 = 'Klient se zavazuje poskytnout Společnosti Zápůjčku bankovním převodem na bankovní účet Společnosti číslo 5487097002/5500.'
	doc.text(lMargin + 8, 90, 'a)  ')
    doc.text(lMargin + 15, 90, doc.splitTextToSize(paragraph_02, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_03 = 'Jako potvrzení akceptace této smlouvy slouží připsání Zápůjčky ve sjednané výši na účet Společnosti.'
	doc.text(lMargin + 8, 100, 'b)  ')
    doc.text(lMargin + 15, 100, doc.splitTextToSize(paragraph_03, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 115, '3)  Účel')
    doc.setTextColor(0)
    let paragraph_04 = 'Smluvní strany ujednávají, že Klient poskytuje Zápůjčku Společnosti za účelem vyhledávání investičních příležitostí jako například zprostředkování zápůjček dalším subjektům. To za účelem tvorby zisku.'
	doc.text(lMargin + 8, 120, 'a)  ')
    doc.text(lMargin + 15, 120, doc.splitTextToSize(paragraph_04, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_05 = 'Předpokládá se, že Společností zprostředkované investiční příležitosti budou v maximální možné míře jištěny nemovitostmi či jiným hodnotným movitým majetkem.'
	doc.text(lMargin + 8, 135, 'b)  ')
    doc.text(lMargin + 15, 135, doc.splitTextToSize(paragraph_05, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 150, '4)  Úroky')
    doc.setTextColor(0)
    let paragraph_06 = 'Smluvní strany sjednávají Zápůjčku ujednanou v této smlouvě s pevnou úrokovou sazbou ve výši ' + user.interest + ' % ročně.'
	doc.text(lMargin + 8, 155, 'a)  ')
    doc.text(lMargin + 15, 155, doc.splitTextToSize(paragraph_06, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_07 = 'Smluvní strany sjednávají měsíční četnost výplaty úroků (dále jen „Období“)'
	doc.text(lMargin + 8, 165, 'b)  ')
    doc.text(lMargin + 15, 165, doc.splitTextToSize(paragraph_07, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_08 = 'Úroky za zvolené Období v bodě 5) odstavec b) této smlouvy jsou splatné vždy do konce měsíce následujícího po uplynutí každého Období, nejpozději však s vrácením Zápůjčky.'
	doc.text(lMargin + 8, 170, 'c)  ')
    doc.text(lMargin + 15, 170, doc.splitTextToSize(paragraph_08, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_09 = 'Vydlužitel se zavazuje zaplatit úroky bankovním převodem na komunikační účet Klienta.'
	doc.text(lMargin + 8, 180, 'd)  ')
    doc.text(lMargin + 15, 180, doc.splitTextToSize(paragraph_09, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 190, '5)  Změny ve smlouvě')
    doc.setTextColor(0)
    let paragraph_10 = 'V případě změny údajů Klienta uvedených v záhlaví této smlouvy je Klient povinen takovou změnu bez prodlení písemně oznámit Společnosti.'
	doc.text(lMargin + 8, 195, 'a)  ')
    doc.text(lMargin + 15, 195, doc.splitTextToSize(paragraph_10, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_11 = 'Společnost nenese zodpovědnost za případné ztráty Klienta způsobené nenahlášením změněných údajů. '
	doc.text(lMargin + 8, 205, 'b)  ')
    doc.text(lMargin + 15, 205, doc.splitTextToSize(paragraph_11, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_12 = 'Společnost je oprávněna v případě nenahlášení změněných údajů Klientem vyúčtovat Klientovi náklady Společnosti s tím spojené.'
	doc.text(lMargin + 8, 215, 'c)  ')
    doc.text(lMargin + 15, 215, doc.splitTextToSize(paragraph_12, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_13 = 'Vyúčtování nákladů Společnosti dle bodu 6 odstavec b) a c) této smlouvy je Společnost oprávněna vyúčtovat jak v průběhu trvání smlouvy, tak nejpozději zápočtem při vrácení zápůjčky.'
	doc.text(lMargin + 8, 225, 'd)  ')
    doc.text(lMargin + 15, 225, doc.splitTextToSize(paragraph_13, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 245, '6)  Vrácení Zápůjčky')
    doc.setTextColor(0)
    let paragraph_14 = 'Společnost se zavazuje vrátit Klientovi Zápůjčku nejpozději do ' + user.duration + ' let a 2 měsíců od připsání Zápůjčky v plné výši na účet Společnosti. '
	doc.text(lMargin + 8, 250, 'a)  ')
    doc.text(lMargin + 15, 250, doc.splitTextToSize(paragraph_14, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_15 = 'Vrácením Zápůjčky se rozumí připsání celé zapůjčené částky na uvedený komunikační účet Klienta. '
	doc.text(lMargin + 8, 260, 'b)  ')
    doc.text(lMargin + 15, 260, doc.splitTextToSize(paragraph_15, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(100)
    doc.text(lMargin, 280, 'UP Future, s.r.o.')
    doc.text(lMargin, 285, 'Korunní 2569/108, Vinohrady (Praha 10)')
    doc.text(lMargin, 290, '101 00 Praha')
    doc.text(120, 285, 'info@futureinvest.cz')


    doc.addPage()
    doc.setTextColor(0)

    doc.addImage(image, 'PNG', 12, 9, 36, 20)

    let paragraph_16 = '2 měsíce nad sjednanou dobu ' + user.duration + ' let jsou pro případ nesplnění povinnosti ujednané v bodě 6 odstavec a) této smlouvy sjednány jako administrativní měsíce. Jeden před a poslední po ' + user.duration + ' letém období sjednané Zápůjčky. '
	doc.text(lMargin + 8, 35, 'c)  ')
    doc.text(lMargin + 15, 35, doc.splitTextToSize(paragraph_16, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 55, '7)  Rozhodné právo')
    doc.setTextColor(0)
    let paragraph_17 = 'Tato Smlouva se řídí právním řádem České republiky, zejména ust. 2390 a násl. zák. č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů. '
	doc.text(lMargin + 8, 60, 'a)  ')
    doc.text(lMargin + 15, 60, doc.splitTextToSize(paragraph_17, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 75, '8)  Odstoupení od smlouvy')
    doc.setTextColor(0)
    let paragraph_18 = 'Od této smlouvy je možné odstoupit ve lhůtě 14 dnů ode dne jejího uzavření, a to písemnou výpovědí doručenou ve stanoveném termínu na kontaktní adresu Společnosti uvedenou v této smlouvě.'
	doc.text(lMargin + 8, 80, 'a)  ')
    doc.text(lMargin + 15, 80, doc.splitTextToSize(paragraph_18, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_19 = 'Klient v případě odstoupení nese náklady spojené s vracením peněz.'
	doc.text(lMargin + 8, 95, 'b)  ')
    doc.text(lMargin + 15, 95, doc.splitTextToSize(paragraph_19, (pdfInMM-lMargin-rMargin - 15)))

    doc.setTextColor(94, 112, 104)
    doc.text(lMargin, 105, '9)  Závěrečná ustanovení')
    doc.setTextColor(0)
    let paragraph_20 = 'V této Smlouvě, pokud z kontextu jasně nevyplývá jinak, zahrnuje význam slova v jednotném čísle rovněž význam daného slova v množném čísle a naopak, význam slova vyjadřujícího určitý rod zahrnuje rovněž ostatní rody. Nadpisy jsou uváděny pouze pro přehlednost a nemají vliv na výklad této Smlouvy. '
	doc.text(lMargin + 8, 110, 'a)  ')
    doc.text(lMargin + 15, 110, doc.splitTextToSize(paragraph_20, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_21 = 'Nevymahatelnost či neplatnost kteréhokoliv ustanovení této Smlouvy nemá vliv na vymahatelnost či platnost zbývajících ustanovení této Smlouvy, pokud z povahy nebo obsahu takové ustanovení nevyplývá, že nemůže být odděleno od ostatního obsahu této Smlouvy. '
	doc.text(lMargin + 8, 130, 'b)  ')
    doc.text(lMargin + 15, 130, doc.splitTextToSize(paragraph_21, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_22 = 'Tato Smlouva představuje úplné ujednání mezi Smluvními stranami ve vztahu k předmětu této Smlouvy a nahrazuje veškeré předchozí ujednání ohledně předmětu této Smlouvy.'
	doc.text(lMargin + 8, 150, 'c)  ')
    doc.text(lMargin + 15, 150, doc.splitTextToSize(paragraph_22, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_23 = 'Tato Smlouva může být změněna pouze písemnými dodatky podepsanými všemi Smluvními stranami.'
	doc.text(lMargin + 8, 165, 'd)  ')
    doc.text(lMargin + 15, 165, doc.splitTextToSize(paragraph_23, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_24 = 'Tato Smlouva je vyhotovena ve dvou stejnopisech. Každá Smluvní strana obdrží 1 stejnopis této Smlouvy elektronickou formou.'
	doc.text(lMargin + 8, 175, 'e)  ')
    doc.text(lMargin + 15, 175, doc.splitTextToSize(paragraph_24, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_25 = 'Každá ze Smluvních stran nese své vlastní náklady vzniklé v důsledku uzavírání této Smlouvy. Vyjma specificky určených v této Smlouvě. '
	doc.text(lMargin + 8, 185, 'f)  ')
    doc.text(lMargin + 15, 185, doc.splitTextToSize(paragraph_25, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_26 = 'Tato Smlouva nabývá platnosti a účinnosti v okamžiku jejího podpisu všemi Smluvními stranami.'
	doc.text(lMargin + 8, 195, 'g)  ')
    doc.text(lMargin + 15, 195, doc.splitTextToSize(paragraph_26, (pdfInMM-lMargin-rMargin - 15)))
    let paragraph_27 = 'Smluvní strany si tuto Smlouvu přečetly, souhlasí s jejím obsahem a prohlašují, že je ujednána svobodně a bez nátlaku nebo za nevýhodné situace žádné ze Smluvních stran. '
	doc.text(lMargin + 8, 205, 'h)  ')
    doc.text(lMargin + 15, 205, doc.splitTextToSize(paragraph_27, (pdfInMM-lMargin-rMargin - 15)))

    let paragraph_28 = 'NA DŮKAZ ČEHOŽ SMLUVNÍ STRANY PŘIPOJUJÍ SVÉ PODPISY ELEKTRONICKOU, ČI PÍSEMNOU FORMOU.'
    doc.text(lMargin + 15, 220, doc.splitTextToSize(paragraph_28, (pdfInMM-lMargin-rMargin - 15)))

    let date = new Date()
    let date_today = date.getDate() + '. ' +  (date.getMonth() + 1) + '. ' + date.getFullYear()
   
    doc.setFontSize(14)
    doc.setTextColor(94, 112, 104)
    doc.text(lMargin + 110, 240, 'V Praze dne ' + date_today)
    doc.addImage(signature, 'PNG', lMargin + 114, 245, 36, 20)
    doc.text(lMargin + 115, 260, '..................................')
    doc.setFontSize(12)
    doc.text(lMargin + 115, 270, 'UP Future, s.r.o.')

    doc.setFontSize(14)
    doc.text(lMargin + 10, 240, 'V Praze dne ' + date_today)
    doc.setFontSize(22)
    doc.text(lMargin + 15, 260, '... ' + user.sms_code + ' ...')
    doc.setTextColor(100)
    doc.setFontSize(10)
    doc.text(lMargin + 15, 265, 'elektronický podpis sms kódem')


    doc.setFontSize(12)
    doc.setTextColor(100)
    doc.text(lMargin, 280, 'UP Future, s.r.o.')
    doc.text(lMargin, 285, 'Korunní 2569/108, Vinohrady (Praha 10)')
    doc.text(lMargin, 290, '101 00 Praha')
    doc.text(120, 285, 'info@futureinvest.cz')


    //doc.save(`report_${dateStr}.pdf`)    
    var blob = doc.output('blob')    

    let res = await uploadFile(blob, 'smlouva_' + doc_id, pageApiUrl)
    if(res.data !== ""){
        console.log('>> [ error ] pdf generator or uploaded')    
        console.log(res.data)    
        console.log(res)   
        return null 
    } else {
        console.log('>> pdf sucessfully generated and uploaded')    
        return 'smlouva_' + doc_id + '.pdf'
    }

    

}    

export default generatePDF    
