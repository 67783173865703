/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import Axios from 'axios'
import { MainContext } from '../contexts/MainContext'
import './css/FileUploadForm.css'

function FileUploadForm(props) {
    let uid = 0
    if(props.uid !== undefined){
        uid = props.uid
    }
    const { pageApiUrl } = useContext(MainContext);

    const UPLOAD_ENDPOINT = pageApiUrl + 'upload.php'
    const [file, setFile] = useState(null);
    const [element, setElement] = useState(null)

    useEffect(() => {       // wait for file to be loaded than run function
        if(file && props.autoupload){
            setTimeout(() => {
                onSubmit(element)
            }, 500)
        }
    }, [file])

    const onSubmit = async (e) => {
        e.preventDefault()
        if(!file){
            console.log(">!file")
            props.callBack({
                response: null,
                caller: props.caller,
                type: props.type,
                error: true
            })
            return
        }
        let res = await uploadFile(file);

        console.log(res.data)

        props.callBack({
            response: res.data,
            caller: props.caller,
            type: props.type,
            error: false
        })

        setTimeout(() => {
            
            e.target.value = null
            setFile(null)
        }, 2000)
    }

    const onChange = (e) => {
        setFile(e.target.files[0])
        setElement(e)
    }

    async function uploadFile(file) {
        const formData = new FormData();
        formData.append('avatar', file)

        console.log(formData, file)

        return await Axios.post(UPLOAD_ENDPOINT, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }

    return (
        <div className="file_upload_form">
            <form onSubmit={onSubmit} className={props.className} id={"file_form_" + uid}>
                <input type="file" name={"file_" + uid} id={"file_" + uid} className="file_input" onChange={onChange} />
                <div className="file_upload_form_grid">
                    <label htmlFor={"file_" + uid} className="file_input_label">vybrat soubor</label>
                    {!props.autoupload && <button type="submit" className={`button button--small button--outlined btn ${file ? 'focus' : ''} `} >Nahrát</button>}
                </div>
            </form>
        </div>
    )

}

export default FileUploadForm;