import React from 'react'
import './css/SwitchUI.css'

export default function SwitchUI(props) {
    return (
        <>
            <div className="switch_container">
                <p>{props.text}</p>
                <input type="checkbox" id={`switch_ui${props.uid}`} className="switch_ui" defaultChecked={props.defaultChecked} onChange={props.onChange}/>
                <label htmlFor={`switch_ui${props.uid}`} className={`switch_ui_label ${props.className}`}></label>
            </div>
        </>
    );
} 