/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import './css/UserInvestmentOverview.css'
import { MainContext } from '../contexts/MainContext'
import CircleProgressBar from './CircleProgressBar';

// converts SQL timestamp to plain text date (without time)
function timestampToDate(timestampDate) {
    var dateStr = timestampDate;
    var a = dateStr.split("_");
    return a[0];
}

function UserInvestmentOverview(props) {
    const { getTableWhereApi, userData, updateUI, convertTimestamp } = useContext(MainContext)

    // wait for userData to be loaded then run function
    useEffect(() => {
        if (userData.id) {
            getInvestmentData()
        }
    }, [userData, updateUI])

    const [investmentData, setInvestmentData] = useState([])

    const getInvestmentData = async () => {
        let investmentData = await getTableWhereApi('investments', 'user_id', props.userId, 'int')

        // sort by start_date
        if (Array.isArray(investmentData) && investmentData.length > 1) {
            investmentData.sort((a, b) => convertTimestamp(a.start_date) - convertTimestamp(b.start_date))
        }

        setInvestmentData(investmentData)
    }

    return (
        <div className="user_investment_overview_wrapper">

            <div className="inv_overview_container">

                <div className="investment_table_wrapper">
                    <div className="itw_line line_legend">
                        <p>typ</p>
                        <p>částka</p>
                        <p>úrok</p>
                        <p>začátek</p>
                        <p>konec</p>
                        <p>progres</p>
                    </div>


                    {Array.isArray(investmentData) && investmentData.length !== 0 ?
                        <div className="inv_line_box_wrapper">
                            {Array.isArray(investmentData) && investmentData.map((data, i) => {
                                var modifiedDateStart = timestampToDate(data.start_date)
                                var modifiedDateEnd = timestampToDate(data.end_date)

                                var progressLength = convertTimestamp(data.end_date) - convertTimestamp(data.start_date)
                                var startToNow = Date.now() - convertTimestamp(data.start_date)

                                var progressPerc = Math.round((startToNow / progressLength) * 100) < 100 ? Math.round((startToNow / progressLength) * 100) : 99
                                if (Date.now() <= convertTimestamp(data.end_date)) {
                                    return (
                                        <div className='inv_line_box' key={i}>
                                            <p>{parseInt(data.investment_type) === 1 ? "2 R." : "4 R."}</p>
                                            <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(data.amount)}</p>
                                            <p>{data.interest}%</p>
                                            <p>{modifiedDateStart}</p>
                                            <p>{modifiedDateEnd}</p>
                                            <div className="circular_progressbar_small inv_overview_circ">
                                                <CircleProgressBar data={progressPerc} type="inline" gradient={0} />
                                            </div>
                                        </div>
                                    )
                                }
                                return (null)
                            })}

                            <p className="pmt_context_line">dokončené investice</p>

                            {Array.isArray(investmentData) && investmentData.map((data, i) => {
                                var modifiedDateStart = timestampToDate(data.start_date)
                                var modifiedDateEnd = timestampToDate(data.end_date)

                                if (Date.now() > convertTimestamp(data.end_date)) {
                                    return (
                                        <div className='inv_line_box' key={i}>
                                            <p>{parseInt(data.investment_type) === 1 ? "2 R." : "4 R."}</p>
                                            <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(data.amount)}</p>
                                            <p>{data.interest}%</p>
                                            <p>{modifiedDateStart}</p>
                                            <p>{modifiedDateEnd}</p>
                                            <div className="circular_progressbar_small inv_overview_circ">
                                                <CircleProgressBar data={100} type="inline" gradient={0} />
                                            </div>
                                        </div>
                                    )
                                }
                                return (null)
                            })}
                        </div> :
                        <p className="pmt_context_line_no_inv">žádné investice</p>}

                </div>
            </div>
        </div>



    )
}

export default UserInvestmentOverview
