/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/IdentityDocuments.css'

function IdentityDocuments(props) {
    const { userData } = useContext(MainContext);
    var getUserData = userData

    if (props.user) {
        getUserData = props.user
    } else {

    }

    const [userDocuments, setUserDocuments] = useState({
        personalInformation: {
            valid: false,
        },
        contactInformation: {
            valid: false,
        },
        bankAccount: {
            number: '',
            valid: false
        }
    })

    useEffect(() => {       // wait for userData to be loaded than run function
        if (getUserData.loaded !== false && userData) {
            checkUserData()
        }
    }, [userData])

    const checkUserData = () => {
        if (getUserData.loaded !== false && userData) {
            let personalInformationData = userDocuments.personalInformation
            let contactInformationData = userDocuments.contactInformation
            let bankAccountValid = (getUserData.bank_account && getUserData.bank_account !== undefined && getUserData.bank_account !== '')

            let primaryAddress = JSON.parse(getUserData.primary_address)

            personalInformationData.valid = (getUserData.birth_number && getUserData.name && getUserData.surname)
            contactInformationData.valid = primaryAddress && primaryAddress.hasOwnProperty("street") &&
                (primaryAddress.street && primaryAddress.city && primaryAddress.psc && primaryAddress.state &&
                    getUserData.phone && getUserData.email)

            if (personalInformationData.valid && contactInformationData.valid && bankAccountValid) {
                props.callBack({
                    error: false
                })
            } else {
                props.callBack({
                    error: true,
                    string: 'chybějící údaje'
                })
            }

            setUserDocuments({
                personalInformation: personalInformationData,
                contactInformation: contactInformationData,
                bankAccount: {
                    number: getUserData.bank_account,
                    valid: bankAccountValid
                }
            })
        }
    }


    return (
        <>
            <div className="identity_documents_wrapper">
                <div className="identity_documents_container">

                    <div className={`user_ident_line ${props.edit ? 'edit' : ''} ${userDocuments.personalInformation.valid ? 'valid' : 'invalid'} `}>
                        <p>Vyplněné osobní údaje</p>
                        <div className="status_icon"></div>
                    </div>

                    <div className={`user_ident_line ${props.edit ? 'edit' : ''} ${userDocuments.contactInformation.valid ? 'valid' : 'invalid'} `} >
                        <p>Adresa a kontaktní údaje</p>
                        <div className="status_icon"></div>
                    </div>

                    <div className={`user_ident_line ${userDocuments.bankAccount.valid ? 'valid' : 'invalid'} `} >
                        <p>Potvrzení bankovního účtu</p>
                        <div className="status_icon"></div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default IdentityDocuments