import React from 'react'
import "./css/loading.css"

function Loading(props) {

    return(<>
        <div id="loading" className={props.type}>
            <div className="vertical-centered-box">
                <div className="content">
                    <div className="loader-circle"></div>
                    <div className="loader-line-mask">
                        <div className="loader-line"></div>
                    </div>
                    {/* <div className="loading_logo"></div> */}
                </div>
            </div>
        </div>
    </>)
}

export default Loading