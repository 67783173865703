/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import '../css/app.css'

function Error404() {

    return (
        <div className="er_404">
            <div className="content_404">
                <h1>404</h1>
                <h3>Stránka nebyla nalezena</h3>
            </div>
        </div>
    )

}

export default Error404;