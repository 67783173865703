import React, { useContext } from 'react'
import { MainContext } from '../contexts/MainContext'
import {Link} from 'react-router-dom'
import './css/Notifications.css'   

function Notifications(){
    const { notifData, readNotifications } = useContext(MainContext);

    if(notifData.data.length === 0){
        return (
            <div className="container content_box notifications_box">
            <p className="cb_label_left">upozornění</p>
            <div className="notif_line_wrapper">
                <p className="empty_label">žádné upozornění</p>
            </div>
            
        </div>
        )
    }

    // set notifications as read after 1s from opeing 
    if(notifData.unread.length !== 0){
        setTimeout(function(){
            readNotifications(notifData.unread)
        }, 1000)
    }

    return (
        <>
            <div className="container content_box notifications_box">
                <p className="cb_label_left">upozornění</p>
                <div className="notif_line_wrapper">
                {notifData.data.map((data, i) => {

                    if(data.link === undefined || data.unread === undefined || data.type === undefined || data.content === undefined){
                        return <></>
                    }

                    return (
                        <Link to={data.link} key={i}>
                            <div className={`notif_line ${data.unread === '1' ? 'unread' : ''} ${data.type === '2' ? 'alert' : ''}`} key={i}>
                                <p>{data.content}</p>
                            </div>
                        </Link>
                    )
                
                })}
                </div>
                
            </div>
        </>
    )
}

export default Notifications
