/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'
import './css/FAQ.css'

function FAQ() {
    const { userData, getTableWhereApi } = useContext(MainContext);
    const [faqData, setFaqData] = useState([])

    useEffect(() => {
        if (userData.loaded !== false) {
            getFaqData()
        }
    }, [userData])

    const getFaqData = async () => {
        let requestFaqData = await getTableWhereApi('text_data', 'item_type', 'faq', 'string')
        requestFaqData = requestFaqData.reverse()
        // requestFaqData = requestFaqData.filter(item => item.item_type === 'faq')

        if (Array.isArray(requestFaqData) && requestFaqData.length % 2 === 0) {
            
            let newArrayFAQ = []
            for (let i = 0; i < requestFaqData.length; i += 2){
                let headline = requestFaqData[i]
                let content = requestFaqData[i+1]

                newArrayFAQ.push({
                    headline: headline,
                    content: content
                })
            }
            
            setFaqData(newArrayFAQ)
        }
    }

    return (<>
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage transactions_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Otázky a odpovědi</h3>
                    <p className="cb_fullpage_sublabel_right">Často kladené dotazy našich klientů</p>
                </div>

                <div className="faq_box_container">
                    {Array.isArray(faqData) && faqData.length !== 0 && faqData.map((data, i) => {
                        return (
                            <div className="faq_item" key={i}>
                                <h3 className="faq_item_name">{data.headline.content}</h3>
                                <p className="faq_item_content">{data.content.content}</p>
                            </div>
                        )
                    })}  
                </div>
              
            </div>

            <br />
            <br />
        </div>

    </>)
}

export default FAQ