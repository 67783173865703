/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import { Link } from 'react-router-dom'
import '../css/app.css'
import './css/ForgotenPassword.css'
import Loading from "../components/Loading";

function ForgotenPassword(props) {
    const { forgotenPasswordApi, getError } = useContext(MainContext);

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {

    }, [])

    const initialState = {
        userInfo: {
            username: ''
        },
        errorMsg: '',
        successMsg: '',
    }
    const [state, setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        setIsLoading(true)

        const data = await forgotenPasswordApi(state.userInfo);
        if (data.success) {
            setState({
                ...initialState,
                successMsg: data.message,
            });

            setIsSuccess(true)
        }
        else {
            console.warn(data)

            alert("Nastala chyba " + data.message)
            getError("Nastala chyba " + data.message)

            setState({
                ...state,
                successMsg: '',
                errorMsg: data.message
            });
        }
        
        setIsLoading(false)
    }

    // On change the Input Value (username, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });


    }

    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="notification notification--error">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="notification notification--success">{state.successMsg}</div>;
    }

    let login_page_bg = ''
    if (props.login_bg) {
        login_page_bg = 'login_page'
    } else {
        login_page_bg = ''
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    // check user permissions

    return (
        <div className={`_loginRegister ${login_page_bg}`}>
            <div className="register_form_container">
                <Link to="/"> <div className="close_bnt"></div></Link>

                <div className="container content_box cb-small forgoten_pass_container">
                    <h1 className="main_h1">Obnovení zapomenutého hesla</h1>
                    <h2 className="sec_headline">login pro přihlášení je Váš email</h2>
                    <form onSubmit={submitForm} noValidate>
                        <div className="reg_inner_conainer">
                            <div className="form-control">
                                <label className="text-gray inp-label">email</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="email" name="username" className="input" type="text" required value={state.userInfo.username} onChange={onChangeValue} />
                                </div>
                            </div>
                        </div>

                        <div className="container res_pass_link">
                            <div className="row reg_btns">
                                {/* <Link to="/" ><button className="button button--outlined button--small aligner aligner--centerVertical back_btn">zpět</button></Link> */}

                                <div className="form-control">
                                    <button type="submit" className="button button--primay button--small aligner aligner--centerVertical">Odslat link</button>
                                </div>
                            </div>
                        </div>

                        <p className='register_link_legend'>{isSuccess ? "Link pro obnovení hesla Vám byl zaslán na email." : ""}</p>


                    </form>

                </div>
            </div>


            {errorMsg}
            {successMsg}
        </div>
    );
    
}
export default ForgotenPassword
