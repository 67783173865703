/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/WithdrawAccount.css'
import CircleProgressBar from './CircleProgressBar';
import Loading from './Loading';


function WithdrawAccount(props){
    const { userData, userAccountData, 
        insertTableApi, getTimestemp, mailApi, getTableWhereApi, 
        getTableApi, postTableApi, updateAcountBalance, setUpdateUi, systemData, getSuccess, getError } = useContext(MainContext);

    const [withdrawAmount, setWithdrawAmount] = useState(0)

    const [isLoading, setIsLoading] = useState(false)
    const [withdrawProcentage, setWithdrawProcentage] = useState(0)

    const [allowWithdraw, setAllowWithdraw] = useState(false)

    useEffect(() => {
        console.log("withdraw account", userData)
        // povolene vybery pouze klientum robina zaborovskeho
        if(userData.parent === 10062 || userData.id === 10062){
            setAllowWithdraw(true)
        }
    }, [userData])
    

    const handleClose = () => {
        props.callBack()
    }

    const handleInput = (e) => {
        let value = e.target.value
        setWithdrawAmount(value)
        let accountBalance = userAccountData.amount - userAccountData.locked_amount
        let withdrawProcentage = Math.round((value / accountBalance) * 100)
        if (value > accountBalance){
            withdrawProcentage = 101 
        }

        setWithdrawProcentage(withdrawProcentage)
    };

    const successMsg = (message) => {
        getSuccess(message)
        
        setWithdrawAmount(0)
        setWithdrawProcentage(0)
        setTimeout(function(){
            handleClose()
        }, 4000)
    }

    const handleWithdrawAccount = async () => {
        setIsLoading(true)
        let userAccountData = await updateAcountBalance(userData)
        let error = false
        if(withdrawAmount === 0 || !withdrawAmount){
            getError("zadejte částku kterou chcete vybrat")
            setIsLoading(false)
            return
        }
        if (withdrawAmount > (userAccountData.amount - userAccountData.locked_amount) || withdrawAmount <= 0 ){
            getError("zadaná částka je neplatná")
            setIsLoading(false)
            return
        }

        let adminData = await getAdminData()
        if(!adminData){
            getError("došlo k chybě transakce selahala")
            setIsLoading(false)
            return
        }

        // create withdrawl request record
        let requestTableObj = {
            data: {
                id: null,
                user_id: userData.id,
                type: 'withdrawal_request',
                content: 'Výběr konta',
                amount: withdrawAmount,
                timestemp: getTimestemp()
            },
            post_table: 'user_requests'
        }

        let createRecResponse = await insertTableApi(requestTableObj)
        
        if(createRecResponse.success !== 1){
            getError("Došlo k chybě, kontaktujte technickou podporu.")
            setIsLoading(false)
            return
        }

        // remove funds from user account
        let removeFundResponse = await removeFundsFromAccount()
        if(!removeFundResponse){
            getError("Došlo k chybě, záznam nebyl proveden. Kontaktujte technickou podporu.")
            setIsLoading(false)
            return
        }

        // create transaction
        let createTransactionResponce = await createTransaction()
        if(!createTransactionResponce){
            getError("Došlo k chybě, záznam nebyl proveden. Kontaktujte technickou podporu.")
            setIsLoading(false)
            return
        }

        let mailResponse = await sendMailNotifications(adminData)
        if(!mailResponse){
            getError("Došlo k chybě při odesílání emailu, ale výběr byl zaznamenám.")
            error = true
            // no return -> continue from here maybe next step will be successful
        }
        
        let notifResponse = await createAdminNotifications(adminData)
        if(!notifResponse){
            getError("Došlo k chybě při odesílání notifikací, ale výběr byl zaznamenám.")
            setIsLoading(false)
            return
        }

        updateAcountBalance(userData)
        setUpdateUi(true)

        if(!error){
            successMsg("odesláno")
        }
        setIsLoading(false)
    }

    const getAdminData = async () => {
        let getAdminIds = await getTableWhereApi("users", "user_type", "admin", "string")
        let adminIdArray = []

        if( getAdminIds && getAdminIds.hasOwnProperty('success') && getAdminIds.success === 0){
            return null
        }
        getAdminIds.forEach((e) => {
            adminIdArray.push(e.id)
        })

        let gaeUserData = await getTableApi("user_data")
        let adminData = []

        if( gaeUserData && gaeUserData.hasOwnProperty('success') && gaeUserData.success === 0){
            return null
        }
        gaeUserData.forEach((e) => {
            if(adminIdArray.includes(e.id))
            adminData.push(e)
        })
        return adminData
    }

    const sendMailNotifications = async (adminData) => {
        let amount = new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(withdrawAmount)
        let withdrawProcentage = Math.round((withdrawAmount / (userAccountData.amount - userAccountData.locked_amount)) * 100)
        // let re = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
        let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        // send notification to admins
        for(let i = 0; i < adminData.length; i++){
            if ( !re.test(adminData[i].email) ) {
                continue
            }
            let mailData = {
                mail_to: adminData[i].email,
                subject: 'FTI - Transakce (Výběr konta) klient: ' + userData.id,
                message: ('Klient provedl transakci:<br><br> typ transakce: výběr konta<br>id klienta: ' + userData.id + 
                '<br>jméno: ' + userData.name + ' ' + userData.surname + '<br>částka: ' + amount + '<br>výběr: ' + withdrawProcentage + '%' +
                '<br>číslo účtu: ' + userData.bank_account + 
                '<br>email klienta: ' + userData.email + '<br>datum: ' + getTimestemp())
            }
            let mailResponse = await mailApi(mailData)
            if(mailResponse.Messages[0].Status !== 'success'){
                console.warn("[ error ] --> " + adminData[i].email, mailResponse)
                return false
            }
        }

        // send mail to system email
        let sysMailData = {
            mail_to: systemData.system_email,
            subject: 'FTI - Transakce (Výběr konta) klient: ' + userData.id,
            message: ('Klient provedl transakci:<br><br> typ transakce: výběr konta<br>id klienta: ' + userData.id +
                '<br>jméno: ' + userData.name + ' ' + userData.surname + '<br>částka: ' + amount + '<br>výběr: ' + withdrawProcentage + '%' +
                '<br>číslo účtu: ' + userData.bank_account +
                '<br>email klienta: ' + userData.email + '<br>datum: ' + getTimestemp())
        }
        let sysMailResponse = await mailApi(sysMailData)
        if (sysMailResponse.Messages[0].Status !== 'success') {
            console.warn("[ error ] --> mail notification system " + systemData.system_email, sysMailResponse)
            return false
        }

        // send notification to user
        let mailData = {
            mail_to: userData.email,
            subject: 'Future Invest - Transakce (Výběr konta)',
            message: ('Na vašem účtě proběhla transakce:<br><br> typ transakce: výběr konta<br>částka: ' + amount + 
            '<br>datum: ' + getTimestemp()) + 
            '<br>částka byla odeslána na číslo účtu: ' + userData.bank_account + 
            '<br>Transakce bude zpracována nejpozději do 30ti pracovních dní.'
        }
        let mailResponse = await mailApi(mailData)
        if(mailResponse.Messages[0].Status !== 'success'){
            console.warn("[ error ] --> userMail ", mailResponse)
            return false
        }
        return true
    }

    const createAdminNotifications = async (adminData) => {
        let amount = new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(withdrawAmount)
        for(let i = 0; i < adminData.length; i++){
            let requestTableObj = {
                data: {
                    id: null,
                    user_id: adminData[i].id,
                    type: 1,
                    content: 'Výběr konta - id: ' + userData.id + ' ' + amount,
                    link: "/",
                    destination: 2,
                    timestemp: getTimestemp()
                },
                post_table: 'notifications'
            }

            let createRecResponse = await insertTableApi(requestTableObj)
            if(createRecResponse.success !== 1){
                return false
            }
        }
        return true
    }

    const removeFundsFromAccount = async () => {
        let getUserAccount = await getTableWhereApi("user_accounts", "id", userData.id, "int")
        if( getUserAccount && getUserAccount.hasOwnProperty('success') && getUserAccount.success === 0){
            return false
        }
        // api is internaly protected againts adding money to account balance (amount), only admins allowd
        let newAmonut = getUserAccount[0].amount - withdrawAmount
        let postData = {
            data: [{
                id: userData.id,
                amount: newAmonut,
                locked_amount: getUserAccount[0].lockedAmount
            }],
            changes: [0],
            post_table: 'user_accounts'
        }
        let postUserAccount = await postTableApi(postData)
        if(postUserAccount.success !== 1){
            return false
        }
        return true
    }

    const createTransaction = async () => {
        let withdrawProcentage = Math.round(((withdrawAmount / userAccountData.amount) * 100) * 100) / 100 // round to 2 deciamls
        let transactionTableObj = {
            data: {
                id: null,
                user_id: userData.id,
                amount: 0 - withdrawAmount,
                acount_move: 0 - withdrawProcentage,
                type: 2,
                description: 'Výběr z účtu',
                timestemp: getTimestemp()
            },
            post_table: 'transactions'
        }

        let createTransactionResponse = await insertTableApi(transactionTableObj)
        if(createTransactionResponse.success !== 1){
            return false
        }
        return true
    }

    if (isLoading){
        return(
            <Loading />
        )
    }

    return (<>
        <div className='withdraw_account_container'>

            <div className="close_bnt" onClick={handleClose}></div>

            <div className="overlay_filter_container">

                <div className="container content_box cb-small withdraw_container">
                    <p className="cb_label_right">Vybrat konto {!allowWithdraw && (<span className='red'>Dočasně nedostupné</span>)}</p>

                    <div className='inner_container'>
                        <p className="cb_label_left sec_label">Vyplácení částky</p>

                        <div className="circular_progressbar_box">
                            <div className="textbox">
                                <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(userAccountData.amount - userAccountData.locked_amount)}</p>
                                <p className="amount_label">k dispozici</p>
                            </div>

                            <div className="circular_progressbar">
                                <CircleProgressBar data={withdrawProcentage} />
                            </div>

                        </div>

                        <div className="input_container">
                            <div className="text_edit_item mid_input">
                                <label className="input_label">částka ({userAccountData.currency === "czk" ? "Kč" : userAccountData.currency})</label>
                                <div className="input input-half">
                                    <input type="text" value={withdrawAmount} placeholder="vybrat částku" onChange={e => handleInput(e)} />
                                </div>
                            </div>

                            <button className={"button button--small withdraw_account_btn " + (!allowWithdraw ? "disabled" : "")} onClick={handleWithdrawAccount} >
                                Vybrat konto
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>)
}

export default WithdrawAccount