import React, { useContext, useState } from 'react'
import { MainContext } from '../contexts/MainContext'
import '../css/app.css'
import './css/Login.css'

function Login(props) {

    const { loginUser, isLoggedIn } = useContext(MainContext);
    const initialState = {
        userInfo: {
            name: '',
            password: '',
        },
        errorMsg: '',
        successMsg: '',
    }
    const [state, setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if (data.success && data.token) {
            console.log(data)
            setState({
                ...initialState,
                successMsg: 'Success'
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else {
            console.warn("Login error!")
            setState({
                ...state,
                successMsg: '',
                errorMsg: data.message
            });
            console.log("[ error ] login")
            console.log(data)

        }
    }

    // const handleForgotenPassword = () => {
    //     alert("Tuto funkci pro Vás připravujeme, zatím prosím zašlete požadavek s obnovením hesla na info@futureinvest.cz")
    //     // TODO -> finish function
    // }

    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="notification notification--error">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="notification notification--success">{state.successMsg}</div>;
    }

    let login_page_bg = ''
    if(props.login_bg) {
        login_page_bg = 'login_page'
    }

    return (
        <div className={`_loginRegister ${login_page_bg}`}>

            <div className="login_logo"></div>

            <h1 className="login_header_h1">Klientský portál <span className="gradient_text">Future Invest</span></h1>
           
            <div className="v-spacing-small"></div>

            <div className="container content_box cb_login_form cb-small-dialog">
                <h1 className="text-center">Přihlášení</h1>
                <br />
                <form onSubmit={submitForm} noValidate className="login_form">
                    <div className="form-control">
                        {/* <label className="text-gray">Login</label> */}
                        <div className="input input-fullWidth">
                            <input placeholder="Login" name="name" className="input" type="text" required value={state.userInfo.name} onChange={onChangeValue} />
                        </div>
                    </div>
                    <div className="form-control">
                        {/* <label className="text-gray">Heslo</label> */}
                        <div className="input input-fullWidth">
                            <input placeholder="Heslo" name="password" className="input" type="password" required value={state.userInfo.password} onChange={onChangeValue} />
                        </div>
                    </div>

                    <a href="/forgoten-password"><p className="login_label_small">Zapomenuté heslo?</p></a>

                    <button type="submit" className="button button--small login_btn">Přihlásit se</button>
                    
                </form>
        </div>

        {errorMsg}
        {successMsg}

            {login_page_bg ? <h3 className="copyright">powered by earc 2021 v1.13 (beta)</h3> : ''}

        </div>
    );
}

export default Login;