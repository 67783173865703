/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import {Link} from 'react-router-dom'
import '../css/app.css'
import './css/Register.css'
import Error404 from '../pages/Error404'
import Loading from "./Loading";


function GetRegisterLink( props ) {
    const { rootState, isLoggedIn, getError, createRegistrLink, mailApi } = useContext(MainContext);
    
    const [registerLink, setRegisterLink] = useState("");
    const { isAuth } = rootState;

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        isLoggedIn()

    }, [])

    const initialState = {
        userInfo: {
            username: '',
        },
        errorMsg: '',
        successMsg: '',
    }
    const [state, setState] = useState(initialState);


    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        console.log(">> register")
        
        // console.log(state.userInfo.password, state.userInfo.passwordRepeat)

        let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        if ( ! re.test(state.userInfo.username) ) {
            setIsLoading(false)
            getError("Neplatný email.")
            setState({
                ...state,
                successMsg: '',
                errorMsg: 'Neplatný email.'
            })
            return
        }

        const data = await createRegistrLink(state.userInfo.username);
        if (data.success) {
            setRegisterLink(data.register_link)
            console.log(data.register_link)

            // '<br>Registrační link: <a href="' + data.register_link + '">' + data.register_link + '</a>' +

            let mailObj = {
                mail_to: state.userInfo.username,
                subject: "Registrace do systému Future Invest",
                message: ('Pozvánka na registraci do systému Future Invest <br><br>' + 
                    // '<br>Registrační link: <a href="' + data.register_link + '"> register link </a>' +
                    'link: ' + data.register_link +
                    '<br><br>plantost linku je 7 dní' +
                    
                    '<br><br>Děkujeme za využívání našich služeb. <br>Future Invest')
            }
            let mailResp = await mailApi(mailObj)

            // console.log(mailObj.message)
            // console.log(mailResp)

            if (mailResp.Messages[0].Status !== 'success') {
                console.warn("[ error ] --> userMail ", mailResp)

                alert("Nastala chyba při vytváření registračního linku.")
                getError("Nastala chyba při vytváření registračního linku.")
                setState({
                    ...state,
                    successMsg: '',
                    errorMsg: data.message
                });
                return 
            }

            setState({
                ...initialState,
                successMsg: data.message,
            });
        }
        else {
            console.warn(data)
            alert("Nastala chyba při vytváření registračního linku.")
            getError("Nastala chyba při vytváření registračního linku.")
            setState({
                ...state,
                successMsg: '',
                errorMsg: data.message
            });
        }
        setIsLoading(false)
    }

    // On change the Input Value (username, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });
        

    }

    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="notification notification--error">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="notification notification--success">{state.successMsg}</div>;
    }

    let login_page_bg = ''
    if (props.login_bg) {
        login_page_bg = 'login_page'
    } else {
        login_page_bg = ''
    }

    if (isLoading){
        return(
            <Loading />
        )
    }

    // check user permissions

    if(isAuth){
        return (
            <div className={`_loginRegister ${login_page_bg}`}>
                <div className="register_form_container">
                    <Link to="/"> <div className="close_bnt"></div></Link>

                    <div className="container content_box cb-mid register_container">
                    <h1 className="main_h1">Registrace nového uživatele</h1>
                    <h2 className="sec_headline">login pro přihlášení je Váš email</h2>
                    <form onSubmit={submitForm} noValidate>

                        <div className="reg_inner_conainer">
                            <div className="form-control">
                                <label className="text-gray inp-label">email</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="email" name="username" className="input" type="text" required value={state.userInfo.username} onChange={onChangeValue} />
                                </div>
                            </div>
                        </div>
                          
                        <div className="container reg_link">
                            <div className="row reg_btns">
                                <Link to="/" ><button className="button button--outlined button--small aligner aligner--centerVertical back_btn">zpět</button></Link>

                                <div className="form-control">
                                    <button type="submit" className="button button--primay button--small aligner aligner--centerVertical">Registrovat</button>
                                </div>
                            </div>
                        </div>

                        <p className='register_link_legend'>{registerLink !== "" ? "Registrační link byl odeslán klientovi na email." : ""}</p>

                        <p className='register_link'>Registrační link: <br />  <br /> <a href={ registerLink } target="_blank" rel="noreferrer" >{registerLink}</a></p>

                    </form>

                </div>
                </div>


                {errorMsg}
                {successMsg}
            </div>
        );
    }
    return <Error404/>;
}
export default GetRegisterLink
