/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../contexts/MainContext'
import CreateInvestmentConfig from './CreateInvestmentConfig'
import './css/CreateInvestment.css'
import IdentityDocuments from './IdentityDocuments'
import generatePDF from "./services/pdfGenerator"
import amountToWords from "./services/amountToWords"
import SignInvestment from './SignInvestment'

function CreateInvestment(props){
    const { setOverlayContent, userData, setSystemNotif, pageApiUrl, getTableWhereApi, setAgreementPdfLink, agreementPdfLink, insertTableApi, getTimestemp, mailApi, postTableApi, updateAcountBalance, getTableApi, userAccountData, systemData } = useContext(MainContext);

    let investId = 0

    useEffect(() => {       // wait for userData to be loaded than run function
        if (userData.loaded !== false) {
            setDepositUI(0)
            investId = generateInvestId(investId)
            //alert("Omlouváme se, momentálně není možné vytvořit novou investici. Usilovně na tom pracujeme.") // TEMP
        }
    }, [userData])

    const [UserDocumentsCheck, setUserDocumentsCheck] = useState(false)
    const [invId, setInvId] = useState(0)
    const [UserDocumentsError, setUserDocumentsError] = useState({ status: "neznámý", class: ""})
    const [investmentParam, setInvestmentParam] = useState({
        amount: '',
        interest: 6,
        duration: '2 roky',
        duration_int: 2,
        investment_type: props.type,
    })

    let agreementPdf = ""

    const [generatePdfInProgress, setGeneratePdfInProgress] = useState(false)

    useEffect(() => {
        setDepositUI(actualPage)
    }, [UserDocumentsError])


    useEffect(() => {
        console.log("- investmentParam")

        if (generatePdfInProgress){
            if (investmentParam.amount){
                generatePdfLocal(null, false)
            }
        }
    }, [investmentParam.amount, generatePdfInProgress])

    let actualPage = 0

    const generateInvestId = async (investmentId) => {
        let foundId = false
        let genCount = 0
        let investId = 0

        console.log(">> generate id...")

        if (investmentId === 0) {
            while (!foundId) {
                let generateId = Math.floor(100000 + Math.random() * 900000)
                console.log("gen id --> " + generateId)
                let getInvestmentTable = await getTableWhereApi('investments', 'id', generateId, 'int')
                console.log(getInvestmentTable)
                genCount++
                if (!Array.isArray(getInvestmentTable) || getInvestmentTable.length === 0) {
                    foundId = true
                    setInvId(generateId)
                    investId = generateId
                }
                if (genCount >= 20) {
                    console.warn("Error id can not be generated (20 tries)")
                    break
                }
            }
        }
        return investId
    }

    const handleClose = () => {
        setAgreementPdfLink(null)
        setOverlayContent(<></>)
        props.callBack()
    }

    const handelCallBack = async (status) => {
        console.log(status)
        if (status.error === true) {
            setUserDocumentsError({status: status.string, class: "error"})
            setUserDocumentsCheck(false)
        } else {
            setUserDocumentsCheck(true)
            setUserDocumentsError({ status: "v pořádku", class: "ok" })
        }
    }

    const loadPage = (page) => {
        if (page === 1 && !UserDocumentsCheck){
            setSystemNotif({
                error: <div className="notification notification--error">chybí doklady, nelze pokračovat</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
            }, 5000)
        } else {
            actualPage = page
            setDepositUI(page)
        }
    }

    const handleConfirm = () => {
        if (!UserDocumentsCheck){
            setSystemNotif({
                error: <div className="notification notification--error">chybí doklady, nelze pokračovat</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
            }, 5000)
        }

        //alert("Omlouváme se, momentálně není možné vytvořit novou investici. Usilovně na tom pracujeme.") // TEMP


        handleConfirmVerify()        // SMS api connect -> commnet to disable sms send
    }

    const handelCallBackData = async (investmentDataParam) => {
        setInvestmentParam(investmentDataParam)
    }

    const generatePdfCallBack = async (amount) => {
        setGeneratePdfInProgress(false)
        setTimeout(setGeneratePdfInProgress(true), 3000)
    }

    const generatePdfLocal = async (sms_code, withCode) => {
        console.log("generate PDF ...")

        let getGenerateId = invId

        

        if (!userData.primary_address || !userData.birth_number || !userData.phone || !userData.email || !userData.bank_account || !userData.name || !userData.surname ){
            console.warn("Error missing user data", userData)
            setSystemNotif({
                error: <div className="notification notification--error">Chybí data o uživateli, vyplňte prosím všechny položky v nastavení účtu.</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
                handleClose()
            }, 10000)
            return
        }

        let amountInWords = amountToWords(String(investmentParam.amount), userAccountData.currency)
        let userDataPdf = {
            doc_id: getGenerateId,
            name: userData.name + ' ' + userData.surname,
            birth_number: userData.birth_number,
            address: JSON.parse(userData.primary_address).street + ', ' + JSON.parse(userData.primary_address).psc + ' ' + JSON.parse(userData.primary_address).city + ', ' + JSON.parse(userData.primary_address).state,
            con_address: JSON.parse(userData.primary_address).street + ', ' + JSON.parse(userData.primary_address).psc + ' ' + JSON.parse(userData.primary_address).city + ', ' + JSON.parse(userData.primary_address).state,
            tel: userData.phone,
            email: userData.email,
            bank_account: userData.bank_account,
            amount: investmentParam.amount,
            amount_words: amountInWords,
            interest: investmentParam.interest,
            duration: investmentParam.duration_int,
            sms_code: !sms_code ? '______' : sms_code
        }
        if (userData.secondary_address !== "" && userData.secondary_address && JSON.parse(userData.secondary_address).city !== ""){
            userDataPdf.con_address = JSON.parse(userData.secondary_address).street + ', ' + JSON.parse(userData.secondary_address).psc + ' ' + JSON.parse(userData.secondary_address).city + ', ' + JSON.parse(userData.secondary_address).state
        }

        let getPdf = await generatePDF(userDataPdf, pageApiUrl, userAccountData.currency)
        let pdfLink = pageApiUrl + 'uploads/' + getPdf
        console.log(getPdf)
        console.log(pdfLink)
        if (!withCode){
            setAgreementPdfLink(pdfLink)
            setDepositUI(1)
        }
        agreementPdf = pdfLink

    }

    const refreshPage = () => {
        window.location.reload(false);
    }

    const blockFundsAccount = async (amount) => {
        let getUserAccount = await getTableWhereApi("user_accounts", "id", userData.id, "int")
        if (getUserAccount && getUserAccount.hasOwnProperty('success') && getUserAccount.success === 0) {
            return false
        }
        // api is internaly protected againts adding money to account balance (amount), only admins allowd
        let newLockedAmonut = parseInt(getUserAccount[0].locked_amount) + amount
        let postData = {
            data: [{
                id: userData.id,
                amount: getUserAccount[0].amount,
                locked_amount: newLockedAmonut
            }],
            changes: [0],
            post_table: 'user_accounts'
        }
        let postUserAccount = await postTableApi(postData)
        if (postUserAccount.success !== 1) {
            return false
        }
        return true
    }

    const getAdminData = async () => {
        let getAdminIds = await getTableWhereApi("users", "user_type", "admin", "string")
        let adminIdArray = []

        if (getAdminIds && getAdminIds.hasOwnProperty('success') && getAdminIds.success === 0) {
            return null
        }
        getAdminIds.forEach((e) => {
            adminIdArray.push(e.id)
        })

        let gaeUserData = await getTableApi("user_data")
        let adminData = []

        if (gaeUserData && gaeUserData.hasOwnProperty('success') && gaeUserData.success === 0) {
            return null
        }
        gaeUserData.forEach((e) => {
            if (adminIdArray.includes(e.id))
                adminData.push(e)
        })
        return adminData
    }

    const sendMailNotifications = async (adminData) => {
        let amount = new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(parseInt(investmentParam.amount))
        let investmentProcentage = Math.round((parseInt(investmentParam.amount) / (userAccountData.amount - userAccountData.locked_amount)) * 100)
        // let re = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
        let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        let dateEnd = new Date(new Date().setFullYear(new Date().getFullYear() + investmentParam.duration_int))
        // send notification to admins
        for (let i = 0; i < adminData.length; i++) {
            if (!re.test(adminData[i].email)) {
                continue 
            }
            let mailData = {
                mail_to: adminData[i].email,
                subject: 'FTI - Nová investice klient: ' + userData.id,
                message: ('Klient založil novou investici:<br><br> typ: investice ' + investmentParam.duration + '<br>id: ' + investId + '<br>úrok: ' + investmentParam.interest +'% p.a.<br>id klienta: ' + userData.id +
                    '<br>jméno: ' + userData.name + ' ' + userData.surname + '<br>částka: ' + amount + '<br>investice v procentech: ' + investmentProcentage + '%' +
                    '<br>smlouva: ' + agreementPdf +
                    '<br><br>email klienta: ' + userData.email + '<br>datum: ' + getTimestemp())
            }
            let mailResponse = await mailApi(mailData)
            if (mailResponse.Messages[0].Status !== 'success') {
                console.warn("[ error ] --> " + adminData[i].email, mailResponse)
                return false
            }
        }
        // send mail to system email
        let sysMailData = {
            mail_to: systemData.system_email,
            subject: 'FTI - Nová investice klient: ' + userData.id,
            message: ('Klient založil novou investici:<br><br> typ: investice ' + investmentParam.duration + '<br>id: ' + investId + '<br>úrok: ' + investmentParam.interest + '% p.a.<br>id klienta: ' + userData.id +
                '<br>jméno: ' + userData.name + ' ' + userData.surname + '<br>částka: ' + amount + '<br>investice v procentech: ' + investmentProcentage + '%' +
                '<br>smlouva: ' + agreementPdf +
                '<br><br>email klienta: ' + userData.email + '<br>datum: ' + getTimestemp())
        }
        let sysMailResponse = await mailApi(sysMailData)
        if (sysMailResponse.Messages[0].Status !== 'success') {
            console.warn("[ error ] --> mail notification system " + systemData.system_email, sysMailResponse)
            return false
        }

        // send notification to user
        let mailData = {
            mail_to: userData.email,
            subject: 'Future Invest - Nová investice',
            message: ('Potvrzení založení investice:<br><br>investice: ' + investmentParam.duration + '<br>id: ' + investId + '<br>úrok: ' + investmentParam.interest +'% p.a.<br>částka: ' + amount +
                '<br>počátek investice: ' + getTimestemp()).split("_")[0] +
                '<br>konec investice: ' + getTimestemp(dateEnd).split("_")[0] +
                '<br>smlouva: ' + agreementPdf +
                '<br><br>Děkujeme za využívání našich služeb. <br>Future Invest'
        }
        let mailResponse = await mailApi(mailData)
        if (mailResponse.Messages[0].Status !== 'success') {
            console.warn("[ error ] --> userMail ", mailResponse)
            return false
        }
        return true
    }

    const createAdminNotifications = async (adminData) => {
        let amount = new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(parseInt(investmentParam.amount))
        for (let i = 0; i < adminData.length; i++) {
            let requestTableObj = {
                data: {
                    id: null,
                    user_id: adminData[i].id,
                    type: 1,
                    content: 'Nová Investice user_id: ' + userData.id + ' ' + amount,
                    link: "/",
                    destination: 2,
                    timestemp: getTimestemp()
                },
                post_table: 'notifications'
            }

            let createRecResponse = await insertTableApi(requestTableObj)
            if (createRecResponse.success !== 1) {
                return false
            }
        }
        return true
    }

    const handleSuccessCallBack = async (code) => {

        console.log(">> handleSuccessCallBack")
        console.log(code)
        console.log(investmentParam)

        await generatePdfLocal(code, true)

        // TODO: 
        /*  - add agreement to db OK
            - create investment in db OK
            - block founds -> OK
            - reload main ui -> OK
            - send mail notifications -> OK
        */

        // --- add agreement to db ---
        if (invId === 0){
            console.warn(">> error no investment id", investId, invId)

            setSystemNotif({
                error: <div className="notification notification--error">Nastala chyba</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
            }, 5000)
            return

        }
        investId = invId

        let pdfFileName = agreementPdf.split("/")
        pdfFileName = pdfFileName[pdfFileName.length - 1]
        let agrResponse = await insertTableApi({
            data: {
                id: null,
                user_id: userData.id,
                investment_id: investId,
                file_name: pdfFileName,
                timestemp: getTimestemp()
            },
            post_table: "user_documents"
        })

        console.log(agrResponse)
        if (!agrResponse.success){
            setSystemNotif({
                error: <div className="notification notification--error">Nastala chyba při ukládaní smlouvy.</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
            }, 5000)
            return
        }

        // --- create investment in db ---
        let dateEnd = new Date(new Date().setFullYear(new Date().getFullYear() + investmentParam.duration_int))     // change to 5. in month
        let invResponse = await insertTableApi({
            data: {
                id: investId,
                user_id: userData.id,
                investment_type: investmentParam.investment_type,
                interest: investmentParam.interest,
                amount: parseInt(investmentParam.amount),
                length: investmentParam.duration_int,
                start_date: getTimestemp(),
                end_date: getTimestemp(dateEnd),
            },
            post_table: "investments"
        }, true)

        console.log(investmentParam)
        console.log(invResponse)
        if (!invResponse.success){
            setSystemNotif({
                error: <div className="notification notification--error">Nastala chyba při tvorbě investice.</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
            }, 5000)
            return
        }

        // --- block founds ---
        let blockFundsResp = blockFundsAccount(parseInt(investmentParam.amount))
        if (!blockFundsResp) {
            setSystemNotif({
                error: <div className="notification notification--error">Nastala chyba při tvorbě investice.</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
            }, 5000)
            return
        }

        // --- send notifications mail + sys ---
        let adminData = await getAdminData()
        let mailResponse = await sendMailNotifications(adminData)
        if (!mailResponse) {
            setSystemNotif({
                error: <div className="notification notification--error">Došlo k chybě při odesílání emailu, ale investice byl vytvořena.</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
            }, 5000)
            return
        }

        let notifResponse = await createAdminNotifications(adminData)
        if (!notifResponse) {
            setSystemNotif({
                error: <div className="notification notification--error">Došlo k chybě při odesílání notifikací, ale investice byl vytvořena.</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
            }, 5000)
            return
        }

        setTimeout(function () {
            updateAcountBalance(userData)
            refreshPage()
        }, 5000)

    }

    const handleConfirmVerify = () => {
        console.log(agreementPdfLink, agreementPdf)
        if (agreementPdf){
            setOverlayContent(
                <div className='create_investment_container'>
                    <div className="close_bnt" onClick={handleClose}></div>

                    <div className="overlay_filter_container">
                        <div className="container content_box cb-small create_investment_cb">
                            <p className="cb_label_right">Zadejte kód z sms</p>

                            <SignInvestment callBack={handleClose} successCallBack={handleSuccessCallBack} />

                        </div>
                    </div>
                </div>
            )
        } else {
            setSystemNotif({
                error: <div className="notification notification--error">není zadaná částka</div>
            })
            setTimeout(function () {
                setSystemNotif({ error: '' })
                loadPage(0)
            }, 5000)
        }
    }

    const setDepositUI = (page) => {
        // global state injects jsx to navbar high at level component insted of return jsx
        if (page === 0 ){setOverlayContent(
            <div className='create_investment_container'>
                <div className="close_bnt" onClick={handleClose}></div>

                <div className="overlay_filter_container">
                    <div className="container content_box cb-mid create_investment_cb">
                        <p className="cb_label_right">Nová investice</p>

                        <div className="page_switch_wrapper">
                            <div className="page pg1 active_page" onClick={() => loadPage(0)}>
                                <p>Kontrola dokladů</p>
                            </div>
                            <div className="page pg2" onClick={() => loadPage(1)}>
                                <p>Konfigurace investice</p>
                            </div>
                        </div>

                        <div className="user_documents">
                            <p className="cb_label_left">Kontrola dokladů</p>
                            <p className="sec_label">OP, ŘP a potvrzení bank. účtu</p>
                            <IdentityDocuments edit={false} callBack={handelCallBack} />

                            <p className="user_status">Status <span className={`user_status_inner ${UserDocumentsError.class}`}>{UserDocumentsError.status}</span></p>
                        </div>

                        <button onClick={() => {loadPage(1)} } className="button button--small continue_invest">Pokračovat</button>

                    </div>
                </div>
            </div>

        )} else {
            setOverlayContent(

                <div className='create_investment_container'>
                    <div className="close_bnt" onClick={handleClose}></div>

                    <div className="overlay_filter_container">
                        <div className="container content_box cb-mid create_investment_cb">
                            <p className="cb_label_right">Nová investice</p>

                            <div className="page_switch_wrapper">
                                <div className="page pg1" onClick={() => loadPage(0)}>
                                    <p>Kontrola dokladů</p>
                                </div>
                                <div className="page pg2 active_page" onClick={() => loadPage(1)}>
                                    <p>Konfigurace investice</p>
                                </div>
                            </div>

                            <div className="investment_params">
                                <p className="cb_label_left">Konfigurace investice</p>
                                <p className="sec_label">Nastavení částky investice</p>

                                <CreateInvestmentConfig loadPage={loadPage} callBack={handelCallBackData} investmentType={props.type} generatePdf={generatePdfCallBack} handleConfirm={handleConfirm} />

                            </div>

                            {/* <button onClick={handleConfirm} className="button button--small continue_invest">Podepsat</button> */}

                            
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (<></>)
}

export default CreateInvestment
