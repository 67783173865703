/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'
import './css/News.css'

function News() {
    const { userData, getTableApi } = useContext(MainContext);
    const [newsData, setNewsData] = useState([])

    useEffect(() => {
        if (userData.loaded !== false) {
            getNewsData()
        }
    }, [userData])

    const getNewsData = async () => {
        let requestNewsData = await getTableApi('news')
        requestNewsData = requestNewsData.reverse()
        console.log(">> requestNewsData", requestNewsData)
        if (Array.isArray(requestNewsData)) {
            setNewsData(requestNewsData)
        }
    }

    return (<>
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage transactions_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Aktuality</h3>
                    <p className="cb_fullpage_sublabel_right">Všechny novinky na jednom místě</p>
                </div>

                <div className="news_box_container">
                    {Array.isArray(newsData) && newsData.length !== 0 && newsData.map((data, i) => {
                        let date = data.timestemp.split("_")
                        return (
                            <div className="news_item" key={i}>
                                <h3 className="news_item_name">{data.headline}</h3>
                                <p className="news_item_content">{data.content}</p>
                                <p className="news_item_date">{date[0]}</p>
                            </div>
                        )
                    })}
                </div>


            </div>

            <br />
            <br />
        </div>

    </>)
}

export default News