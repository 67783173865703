/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/SignInvestment.css'

function SignInvestment(props){
    const { userData, getSMSCode, verifySMSCode } = useContext(MainContext)

    useEffect(() => {       // wait for userData to be loaded than run function
        if (userData.loaded !== false) {
            generateSMSCode()
        }
    }, [userData])

    const [smsCode, setSmsCode] = useState("")
    const [smsCodeId, setSmsCodeId] = useState(null)
    const [showIntput, setShowIntput] = useState(true)
    const [signSuccess, setSignSuccess] = useState(false)
    const [signError, setSignError] = useState(false)

    const updateContent = (e) => {
        let value = smsCode;
        value = e.target.value;
        if(value.length <= 6){
            setSmsCode(value)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleConfirm()
        }
    }

    const handleConfirm = async () => {
        if (smsCodeId !== null){
            let verifySMSCodeResponse = await verifySMSCode(parseInt(smsCode), smsCodeId)

            console.log(">> verifySMSCodeResponse")
            console.log(verifySMSCodeResponse)

            setShowIntput(false)

            if (verifySMSCodeResponse.success){
                setSignSuccess(true)
                props.successCallBack(parseInt(smsCode))
                setTimeout(function(){
                    props.callBack()
                }, 5000)
            } else {
                setSignError(true)
                setTimeout(function(){
                    setSignError(false)
                    setShowIntput(true)
                    setSmsCode("")
                }, 3000)
            }
        }
    }

    const generateSMSCode = async () => {

        let getSMSCodeResponse = await getSMSCode()
        // let getSMSCodeResponse = { code_id: 24 }        // --> debug only -> code: 944944

        console.log(">> getSMSCodeResponse")
        console.log(getSMSCodeResponse)
        if (getSMSCodeResponse.code_id){
            setSmsCodeId(getSMSCodeResponse.code_id)
        }
    }

    return(<>

        <div className="sms_code_input">
            
            { showIntput && <>
                <h3 className="sms_code_headline">Zadejte kód z sms který Vám přišel na číslo {userData.phone}</h3>
                <div className="input curency sms_code_input_textbox">
                    <input type="text" id="single-factor-code-text-field" autoComplete="one-time-code" onKeyDown={handleKeyDown} value={smsCode} placeholder="______" onChange={e => updateContent(e)} />
                </div>

                <button onClick={handleConfirm} className="button button--small continue_invest">Ověřit</button>
            </>}

            { signSuccess && <>
                <div className="success-animation">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                </div>
            </>}

            { signError && <>
                <h2 className="wrong_code">Nesprávný kód</h2>

                <div className="error-animation">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                        <path class="checkmark__check" stroke-linecap="round" fill="none" d="M16 16 36 36 M36 16 16 36" />
                    </svg>
                </div>
            </>}


        </div>

    </>)
}

export default SignInvestment