
const amountToWords = (amount, currency) => {
    
    let LANG = "cz"
    let CURR = currency === "eur" ? "EUR" : "CZK"

    function amountToWords(amount) {
        var number = round_float(parseFloat(amount), 2);
        var desetine_str = "";
        var desetine = amount.split(".")[1] ? parseInt((amount.split(".")[1] + "0000").substring(0, 2), 10) : 0;
        var value = Math.floor(number, true);
        var value_str = value + "";
        var cods = {};
        var result = "";
        if (LANG === "en") {
            cods["CZK"] = ["Czech crowns", "Czech crown", "Czech crowns", "haléřů", "haléř", "haléře"];
            cods["EUR"] = ["Euro", "Euro", "Euro", "cents", "cent", "cents"];
            cods["USD"] = ["U.S. dollars", "U.S. dollar", "U.S. dollars", "cents", "cent", "cents"];
            cods["GBP"] = ["pounds", "pound", "pounds", "pence", "penny", "pence"];
        } else {
            cods["CZK"] = ["korun českých", "koruna česká", "koruny české", "haléřů", "haléř", "haléře"];
            cods["EUR"] = ["eur", "euro", "eura", "centů", "cent", "centy"];
            cods["USD"] = ["amerických dolarů", "americký dolar", "americké dolary", "centů", "cent", "centy"];
            cods["GBP"] = ["britských liber", "britská libra", "britské libry", "pencí", "pence", "pence"];
        }
        var codestr = cods[CURR];
        var cstring = "";
        if (value < 0) {
            result = "";
        } else if (value === 0 && parseFloat(desetine) !== 0) {
            result = "";
        } else if (value < 100) {
            cstring = codestr[0];
            if (value === 1) {
                cstring = codestr[1];
            } else if (value > 1 && value <= 4) {
                cstring = codestr[2];
            }
            result = cleanNumber(jednotkyAdesitky(value_str.substring(value_str.length - 2, value_str.length))) + " " + cstring;
            if (value === 1 && LANG !== "en") {
                if (CURR === "CZK" || CURR === "GBP") {
                    result = "jedna " + cstring;
                } else if (CURR === "EUR") {
                    result = "jedno " + cstring;
                } else if (CURR === "USD" || CURR === "CHF") {
                    result = "jeden " + cstring;
                }
            } else if (value === 2 && LANG !== "en") {
                if (CURR === "CZK" || CURR === "EUR" || CURR === "GBP") {
                    result = "dvě " + cstring;
                } else if (CURR === "USD" || CURR === "CHF") {
                    result = "dva " + cstring;
                }
            }
        } else if (value < 1000) {
            result = cleanNumber(stovky_fn(value_str.substring(value_str.length - 3, value_str.length))) + " " + codestr[0];
        } else if (value < 1000000) {
            result = cleanNumber(tisice_fn(value_str.substring(value_str.length - 6, value_str.length))) + " " + codestr[0];
        } else if (value < 1000000000) {
            result = cleanNumber(miliony_fn(value_str.substring(value_str.length - 9, value_str.length))) + " " + codestr[0];
        } else if (value < 1000000000000) {
            result = cleanNumber(miliardy_fn(value_str.substring(value_str.length - 12, value_str.length))) + " " + codestr[0];
        } else {
            result = "";
        }
        if (desetine !== 0) {
            desetine_str = cleanNumber(jednotkyAdesitky(desetine, true));
            var cents = codestr[3];
            if (desetine === 1) {
                cents = codestr[4];
            } else if (desetine > 1 && desetine <= 4) {
                cents = codestr[5];
            }
            result = result + " " + desetine_str + " " + cents;
        }
        return result;
    }
    function jednotkyAdesitky(value, men) {
        var value_str = value + "";
        var jednotky = [];
        var desitky = [];
        if (LANG === "en") {
            jednotky = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
            desitky = ["zero", "ten", "twenty", "thirty", "fourty", "fifty", "sixty", "seventy", "eighty", "ninety"];
        } else {
            jednotky = ["", "jedna", "dva", "tři", "čtyři", "pět", "šest", "sedm", "osm", "devět", "deset", "jedenáct", "dvanáct", "třináct", "čtrnáct", "patnáct", "šestnáct", "sedmnáct", "osmnáct", "devatenáct"];
            desitky = ["", "deset", "dvacet", "třicet", "čtyřicet", "padesát", "šedesát", "sedmdesát", "osmdesát", "devadesát"];
            if (men) {
                jednotky[1] = "jeden";
            }
        }
        if (value === 0) {
            return jednotky[parseInt(value, 10)];
        }
        if (value < 20) {
            return jednotky[parseInt(value, 10)];
        }
        let value2 = value_str.substring(value_str.length - 2, 1);
        let value1 = value_str.substring(value_str.length - 1, value_str.length);
        if (value1 === 0) {
            return desitky[parseInt(value2, 10)];
        }
        return desitky[parseInt(value2, 10)] + " " + jednotky[parseInt(value1, 10)];
    }
    function stovky_fn(value) {
        var value_str = value + "";
        var str = "";
        var jednotk = "";
        var stovky = [];
        if (LANG === "en") {
            jednotk = "one hundred ";
            stovky = ["zero", "hundred", "two hundred", "three hundred", "four hundred", "five hundred", "six hundred", "seven hundred", "eight hundred", "nine hundred"];
        } else {
            jednotk = "jedno sto";
            stovky = ["", "sto", "dvě stě", "tři sta", "čtyři sta", "pět set", "šest set", "sedm set", "osm set", "devět set"];
        }
        if (value < 100) {
            return jednotkyAdesitky(value_str.substring(value_str.length - 2, value_str.length), false);
        }
        let value3 = value_str.substring(value_str.length - 3, 1);
        let value2 = value_str.substring(value_str.length - 2, value_str.length);
        if (value3 === 1) {
            str = jednotk;
        } else {
            str = stovky[parseInt(value3, 10)];
        }
        if (value2 !== 0) str = str + " " + jednotkyAdesitky(value2, false);
        return str;
    }
    function tisice_fn(value) {
        var value_str = value + "";
        var str = "";
        var jednotk = "";
        var tisice = [];
        if (LANG === "en") {
            jednotk = "one";
            tisice = ["zero", "thousand", "thousands", "thousands"];
        } else {
            jednotk = "jeden";
            tisice = ["", "tisíc", "tisíce", "tisíc"];
        }
        if (value < 1000) {
            return stovky_fn(value_str.substring(value_str.length - 3, value_str.length));
        }
        if (value < 2000) {
            str = jednotk + " " + tisice[1];
        } else if (value < 5000) {
            str = stovky_fn(value_str.substring(0, value_str.length - 3));
            str = str + " " + tisice[2];
        } else {
            str = stovky_fn(value_str.substring(0, value_str.length - 3));
            str = str + " " + tisice[3];
        }
        if (value_str.substring(value_str.length - 3, value_str.length) !== 0) {
            str = str + " " + stovky_fn(value_str.substring(value_str.length - 3, value_str.length));
        }
        return str;
    }
    function miliony_fn(value) {
        var value_str = value + "";
        var str = "";
        var jednotk = "";
        var miliony = [];
        if (LANG === "en") {
            jednotk = "one";
            miliony = ["zero", "million", "million", "million"];
        } else {
            jednotk = "jeden";
            miliony = ["", "milion", "miliony", "milionů"];
        }
        if (value < 1000000) {
            return tisice_fn(value_str.substring(value_str.length - 6, value_str.length));
        }
        if (value < 2000000) {
            str = jednotk + " " + miliony[1];
        } else if (value < 5000000) {
            str = stovky_fn(value_str.substring(0, value_str.length - 6));
            str = str + " " + miliony[2];
        } else {
            str = stovky_fn(value_str.substring(0, value_str.length - 6));
            str = str + " " + miliony[3];
        }
        if (value_str.substring(value_str.length - 6, value_str.length) !== 0) {
            str = str + " " + tisice_fn(value_str.substring(value_str.length - 6, value_str.length));
        }
        return str;
    }
    function miliardy_fn(value) {
        var value_str = value + "";
        var jednotk = "";
        var str = "";
        var miliarda = [];
        if (LANG === "en") {
            jednotk = "one";
            miliarda = ["", "billion", "billions", "billions"];
        } else {
            jednotk = "jedna";
            miliarda = ["", "miliarda", "miliardy", "miliard"];
        }
        if (value < 1000000000) {
            return miliony_fn(value_str.substring(value_str.length - 9, value_str.length));
        }
        if (value < 2000000000) {
            str = jednotk + " " + miliarda[1];
        } else if (value === 2000000000) {
            str = "dvě " + miliarda[2];
        } else if (value < 5000000000) {
            str = stovky_fn(value_str.substring(0, value_str.length - 9));
            str = str + " " + miliarda[2];
        } else {
            str = tisice_fn(value_str.substring(0, value_str.length - 9));
            str = str + " " + miliarda[3];
        }
        if (value_str.substring(value_str.length - 9, value_str.length) !== 0) {
            str = str + " " + miliony_fn(value_str.substring(value_str.length - 9, value_str.length));
        }
        return str;
    }
    function round_float(x, n) {
        if (!parseInt(n, 10)) n = 0;
        if (!parseFloat(x)) return false;
        return Math.round(x * Math.pow(10, n)) / Math.pow(10, n);
    }
    function cleanNumber(str) {
        // if (remove_spaces) {
        //     return str.replace(/\s/g, "");
        // }
        return str;
    }


    return amountToWords(amount)
}    

export default amountToWords
