/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import '../css/app.css'
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../contexts/MainContext'

function CmsImages() {
    const { rootState, setActualPage, getTableApi, postTableApi, pageUrl, Axios, humanFileSize } = useContext(MainContext);
    const { isAuth } = rootState;
    let navigate = useNavigate();

    // data -> complete lines from database with changed values
    // changes -> id's of changed lines in table    // more info in MainContext->postTableApi docs
    const initialState = { data: [], changes: [], post_table: 'cms_iamges', sizes: [] }
    const [state, setState] = useState(initialState);
    const [successMsg, setSuccessMsg] = useState('');

    if (!isAuth) {
        navigate('/')
    }

    const renderTableData = async () => {
        const getTableData = await getTableApi('cms_iamges')
        setState({ ...state, data: getTableData, post_table: 'cms_iamges' })       
    }

    // runs function only once on load
    useEffect(() => {
        renderTableData()
        setActualPage('images')
    }, [])
    
    // runs getImgSize after state.data is set
    useEffect(() => {
        getImgSizes()
    }, [state.data, state.sizes]);

    // handle submit
    const updateTable = async (event) => {
        event.preventDefault();
        console.log(state)
        const getResponse = await postTableApi(state);
        if (getResponse.success) {
            setSuccessMsg(<div className="notification notification--success ">Update sucess</div>)
        } else {
            setSuccessMsg(<div className="notification notification--error ">Update error</div>)
        }
        setTimeout(function () {
            setSuccessMsg('')
        }, 5000)
    }

    const getImgSizes = async () => {
        console.log(">> load getImgSizes async")
        console.log(state.data)
        for (let i = 0; i < state.data.length; i++){
            let newSize = state.sizes
            let size = await getFileSizeApi('img/uploaded/' + state.data[i].img_path)
            if(size === '') {size = '0'}
            newSize.push(humanFileSize(parseInt(size)))
            console.log(size)
            setState({ ...state, sizes: newSize })
        }
    }

    const getFileSizeApi = async (fileName) => {
        console.log(">> load getFileSizeApi async")

        const content = await Axios.post('get-file-size.php', {
            get_file_size: fileName
        });
        console.log(content.data)
        console.log(' ')
        return content.data.size
    }

    return (
        <>
            <div className="container cms_page_container">
                <h3 className="page_headline">Editace obrázků</h3>

                <div className="cms_page_wraper">
                    <div className="cards_item img_edit_container" key='0'>
                            {state.data.map((data, i) => {
                                let file_type = data.img_path.split('.')
                                return (
                                    <div className="container content_box cb-small-short" key={data.id}>
                                        <div className="img_edit_item">
                                            <label className="text-gray input_label">{data.img_alt}</label>
                                            <label className="text-gray input_label">{file_type[file_type.length - 1]}</label>
                                            <label className="text-gray input_label">size: {state.sizes[i]}</label>
                                            <img src={pageUrl + 'img/uploaded/' + data.img_path} className="img_edit_prev" alt={data.img_alt} />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="cards_item" key='1'>
                        <div className="cards_item_fixed">

                            <div className={`container content_box cb-small-short action_box_container`}>
                                <div className="action_box_wrpaper">
                                    <p className="label">Uložit změny provedené na webu: </p>
                                    <button onClick={updateTable} className="update_btn">Uložit</button>
                                    {successMsg}
                                </div>
                            </div>

                            <div className='container content_box cb-small-short action_box_container long'>
                                <p className="label">Jazykové verze textů webu: </p>
                                <div className="action_box_wrpaper fr_grid">
                                    <button  className="update_btn">CZ</button>
                                   
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CmsImages;