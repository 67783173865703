/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import { Link } from 'react-router-dom'
import '../css/app.css'
import '../components/css/Register.css'
import Loading from "../components/Loading";

// example: https://futureinvest.cz/register-link?token=Cw%2FJ62Nn%2BsyjWaInvwhovAOjPcceMlHXQWbLWeA0MU6T6%2BBTMA6MFBH2mOXHUmN60fF%2FyEOwT4mt1ewjs9K%2BAM0q6Gp7sWrA57a1wQnudpCUYMjBvTeJxeajqq4qTxLqYx%2BPPaB%2BzfCIsWJ8PITw45&email=kovarjan01@earc.cz

function RegisterLink(props) {
    const { registerUserLink, getError } = useContext(MainContext);

    const [isFlaged, setFlaged] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    let url = new URL(window.location.href)
    let token = url.searchParams.get("token")
    let login = url.searchParams.get("email")

    if (login === null){
        login = "login nebyl nalezen!"
    }

    useEffect(() => {

    }, [])

    const initialState = {
        userInfo: {
            password: '',
            passwordRepeat: '',
            name: '',
            surname: '',
            token: token
        },
        errorMsg: '',
        successMsg: '',
    }
    const [state, setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        setIsLoading(true)

        // console.log(state.userInfo.password, state.userInfo.passwordRepeat)

        if (state.userInfo.password !== state.userInfo.passwordRepeat) {
            setFlaged(true)
        } else {
            setFlaged(false)

            const data = await registerUserLink(state.userInfo);
            if (data.success) {
                setState({
                    ...initialState,
                    successMsg: data.message,
                });
            }
            else {
                console.warn(data)
                alert("Nastala chyba " + data.message)
                getError("Nastala chyba " + data.message)
                setState({
                    ...state,
                    successMsg: '',
                    errorMsg: data.message
                });
            }
        }
        setIsLoading(false)
    }

    // On change the Input Value (username, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });


    }

    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="notification notification--error">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="notification notification--success">{state.successMsg}</div>;
    }

    let login_page_bg = ''
    if (props.login_bg) {
        login_page_bg = 'login_page'
    } else {
        login_page_bg = ''
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    // check user permissions

    return (
        <div className={`_loginRegister ${login_page_bg}`}>
            <div className="register_form_container">
                <Link to="/"> <div className="close_bnt"></div></Link>

                <div className="container content_box cb-mid register_container">
                    <h1 className="main_h1">Registrace nového uživatele</h1>
                    <h2 className="sec_headline">login pro přihlášení je Váš email</h2>
                    <form onSubmit={submitForm} noValidate>
                        <div className="reg_inner_conainer">
                            <div className="form-control">
                                <label className="text-gray inp-label">email</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="email" name="username" className="input inactive" type="text" required defaultValue={login} />
                                </div>
                            </div>
                            <div className="hlaf_inp_grid">
                                <div className="form-control">
                                    <label className="text-gray inp-label">jméno</label>
                                    <div className="input input-fullWidth">
                                        <input placeholder="jméno" name="name" className="input" type="text" required value={state.userInfo.name} onChange={onChangeValue} />
                                    </div>
                                </div>
                                <div className="form-control">
                                    <label className="text-gray inp-label">příjmení</label>
                                    <div className="input input-fullWidth">
                                        <input placeholder="příjmení" name="surname" className="input" type="text" required value={state.userInfo.surname} onChange={onChangeValue} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-control">
                                <label className="text-gray inp-label">heslo</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="••••••••••••" name="password" className="input" type="password" required value={state.userInfo.password} onChange={onChangeValue} />
                                </div>
                            </div>
                            <div className="form-control">
                                <label className="text-gray inp-label">zopakovat heslo</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="••••••••••••" name="passwordRepeat" className={isFlaged ? "has-error input" : "input"} type="password" required value={state.userInfo.passwordRepeat} onChange={onChangeValue} />
                                </div>
                            </div>
                        </div>

                        <div className="container reg_link">
                            <div className="row reg_btns">
                                {/* <Link to="/" ><button className="button button--outlined button--small aligner aligner--centerVertical back_btn">zpět</button></Link> */}

                                <div className="form-control">
                                    <button type="submit" className="button button--primay button--small aligner aligner--centerVertical">Registrovat</button>
                                </div>
                            </div>
                        </div>

                        <p className='register_link_subtitle'>Z bezpečnostních důvodů není možné měnit emailovou adresu (login)</p>


                    </form>

                </div>
            </div>


            {errorMsg}
            {successMsg}
        </div>
    );
    
}
export default RegisterLink
