/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import Login from '../components/Login'
import { MainContext } from '../contexts/MainContext'
import './css/home.css'

import PortfolioOverview from '../components/PortfolioOverview'
import InvestmentOverview from '../components/InvestmentOverview'
import PaymentCalendar from '../components/PaymentCalendar'
import NewInvestmentBox from '../components/NewInvestmentBox'

function Home() {
    const { rootState, setActualPage, isLoggedIn, userData, getTableApi } = useContext(MainContext)
    const { isAuth } = rootState;
    
    useEffect(() => {       // runs function only once
        setActualPage('/')
        if (!isAuth) {
            isLoggedIn()
        }
        if (userData.loaded !== false) {
            getTextData()
        }
    }, [userData])


    const [textData, setTextData] = useState([])

    const getTextData = async () => {
        let requestTextData = await getTableApi('text_data')
        if (Array.isArray(requestTextData) && requestTextData.length > 10) {
            requestTextData = requestTextData.slice(0,11)
            await setTextData(requestTextData)
        }        
    }

        // not working yet this will not work this way
    // const checkUserIsLogedIn = async () => {
    //     let retData  = await isLoggedIn()
    //     if (retData === false) {
    //         console.log("isAuth ret ->", retData)
    //         navigate('/')
    //     }
    // }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         checkUserIsLogedIn()
    //     }, 5000)
    //     return () => clearInterval(interval)
    // }, []);

    // If user Logged in
    if (isAuth) {
        return (
            <div className="page_container">

                <div className="components_container_overview">
                    <div className="component_wrap">
                        <PortfolioOverview/>
                    </div>
                    <div className="component_wrap">
                        <InvestmentOverview/>    
                    </div>
                    <div className="component_wrap">
                        <PaymentCalendar/>
                    </div>
                    <div className="component_wrap">
                        <NewInvestmentBox/>
                    </div>
                </div>

                <div className="main_page_content">
                    <div className="view0 main_page_contentbox" id="view0">

                        <div className="view0_header">
                            <h1 className="view0_header_h1">{textData.length !== 0 && textData[9].content} <span className="gradient_text">{textData.length !== 0 && textData[10].content}</span></h1>
                            <p>{textData.length !== 0 && textData[0].content}</p>
                        </div>

                        <div className="view0_item_grid">
                            <div className="grid_item_box">
                                <div className="grid_item_img img01"></div>
                                <p>{textData.length !== 0 && textData[1].content}</p>
                            </div>
                            <div className="grid_item_box">
                                <div className="grid_item_img img02"></div>
                                <p>{textData.length !== 0 && textData[2].content}</p>
                            </div>
                            <div className="grid_item_box">
                                <div className="grid_item_img img03"></div>
                                <p>{textData.length !== 0 && textData[3].content}</p>
                            </div>
                            <div className="grid_item_box">
                                <div className="grid_item_img img04"></div>
                                <p>{textData.length !== 0 && textData[4].content}</p>
                            </div>
                            <div className="grid_item_box">
                                <div className="grid_item_img img05"></div>
                                <p>{textData.length !== 0 && textData[5].content}</p>
                            </div>
                        </div>

                    </div>

                    <div className="padding"></div> 

                    <div className="view1 main_page_contentbox" id="view1">

                        <div className="view1_header">
                            <h1 className="view1_header_h1">{textData.length !== 0 && textData[6].content} <span className="gradient_text">{textData.length !== 0 && textData[7].content}</span></h1>
                            <p>
                                {textData.length !== 0 && textData[8].content}
                            </p>
                        </div>

                        <div className="sys_prev_img"></div>


                    </div>
                    <div className="bg_waves_img_container">
                        <div className="bg_waves_img"></div>
                    </div>

                    <div className="homepage_logo"></div>

                    <a href="https://web.earc.cz/"><h3 className="copyright">powered by earc 2021 v1.1 (beta)</h3></a>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>


            </div>
        )
    } else {
        return <Login login_bg='true' />
    }
}

export default Home