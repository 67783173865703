/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react"
import { MainContext } from '../contexts/MainContext'
import './css/DepositMoney.css'

function DepositMoney(props){
    const { userData, setOverlayContent, systemData } = useContext(MainContext);

    useEffect(() => {       // wait for userData to be loaded than run function
        if(userData.loaded !== false){
            setDepositUI()
        }
    }, [userData, systemData])

    const handleClose = () => {
        setOverlayContent(<></>)
        props.callBack()
    }

    const setDepositUI = () => {
        // global state injects jsx to navbar high at level component insted of return jsx
        setOverlayContent(
            <div className='deposit_account_container'>

                <div className="close_bnt" onClick={handleClose}></div>

                <div className="overlay_filter_container">

                    <div className="container content_box cb-small withdraw_container">
                        <p className="cb_label_right">Dobít konto</p>

                        <p className="cb_label_left sec_label">Nabití částky</p>
                        <p className="cb_label_left text_box">
                            Pro nabití konta zašlete prostředky na bankovní účet {systemData.deposit_bank_account} s Vaším variabilním symbolem {userData.id} a poznámkou “{ userData.name + ' ' + userData.surname }”
                        </p>
                        <p className="cb_label_left text_box">
                            číslo účtu: {systemData.deposit_bank_account}<br/>
                            variabilní symbol: {userData.id} <br/>
                            poznámka: “{ userData.name + ' ' + userData.surname }”<br/><br/>
                            <span className="sec_text">
                                zahraniční platba: <br />
                                IBAN: {systemData.deposit_bank_account_iban}<br />
                            </span>
                        </p>

                        <img className="qr_payment" src={"http://api.paylibo.com/paylibo/generator/czech/image?accountNumber=" + (systemData.deposit_bank_account).split("/")[0] + "&bankCode=" + (systemData.deposit_bank_account).split("/")[1] + "&currency=CZK&vs=" + userData.id + "&message=" + userData.name + ' ' + userData.surname } alt="qr" />
                                
                    </div>
                </div>

            </div>
        )
    }

    return (<></>)
}

export default DepositMoney