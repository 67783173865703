/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/PaymentCalendar.css'

// converts SQL timestamp to plain text date (without time)
function timestampToDate(timestampDate) {
    var dateStr = timestampDate;
    var a = dateStr.split("_");
    return a[0];
}

function PaymentCalendar(){
    const { getTableWhereApi, userData, convertTimestamp, calculateInterest, userAccountData, getTimestemp } = useContext(MainContext)

    // wait for userData to be loaded then run function
    useEffect(() => {
        if (userData.id && userAccountData) {
            getTransactionData()
            getInvestmentData()
        }
    }, [userData, userAccountData])

    const [transactionData, setTransactionData] = useState([])
    const [nextPayout, setNextPayout] = useState({
        acount_move: 0,
        amount: "",
        timestemp: ""
    })

    const getTransactionData = async () => {
        let transactionData = await getTableWhereApi('transactions', 'user_id', userData.id, 'int')
        if (Array.isArray(transactionData)) {
            transactionData = transactionData.filter((record) => record.type === "1")
            setTransactionData(transactionData.slice(0, 3))
        }    

    }

    const getInvestmentData = async () => {
        let investmentData = await getTableWhereApi('investments', 'user_id', userData.id, 'int')
        let userInvestments = []
        let userActiveInvestments = []
        let payoutAmount = 0
        
        if (Array.isArray(investmentData) && investmentData.length > 0) {
            investmentData.sort((a, b) => convertTimestamp(a.start_date) - convertTimestamp(b.start_date))
            userInvestments = investmentData
        }

        // get all active user investments
        for (let i = 0; i < userInvestments.length; i++){
            let inv = userInvestments[i]
            let progressLength = convertTimestamp(inv.end_date) - convertTimestamp(inv.start_date)
            let startToNow = Date.now() - convertTimestamp(inv.start_date)

            if (progressLength > startToNow){
                userActiveInvestments.push(inv)
            }
        }

        // calculate all monthly interests and count them
        for (let i = 0; i < userActiveInvestments.length; i++) {
            let inv = userActiveInvestments[i]
            let invPayout = calculateInterest(inv.amount, inv.length, inv.interest, 2) / (12 * inv.length)
            payoutAmount += Math.floor(invPayout)
        }
        
        // calculate account move (if aount is at 0 move is 0 - edgecase)
        let accountMove = userAccountData.amount === 0 ? 0 : Math.floor(((payoutAmount / userAccountData.amount) * 100) * 100) / 100 // round to 2 deciamls

        let dateNow = new Date()
        let payoutDate = null

        if (dateNow.getDate() > 5 && dateNow.getMonth() !== 11){            // next month 05.th at 3:00 AM
            payoutDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 5, 3, 0, 0)
        } else if (dateNow.getDate() > 5 && dateNow.getMonth() === 11){     // next year 05.th january at 3:00 AM
            payoutDate = new Date(dateNow.getFullYear() + 1, 0, 5, 3, 0, 0)
        } else {                                                            // this month 05.th at 3:00 AM
            payoutDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 0, 5, 3, 0, 0)
        }


        /// TODO!!! ^^^

        setNextPayout({
            acount_move: accountMove,
            amount: payoutAmount,
            timestemp: getTimestemp(payoutDate)
        })


    }

    return(
        <div className="container content_box component_cb payment_calendar_wrapper">
            <p className="container_box_label">Kalendář výplat</p>

            <div className="payment_calendar_container">

                <div className="payment_table_wrapper">
                    <div className="ptw_line line_legend">
                        <p>datum</p>
                        <p>pohyb konta</p>
                        <p>částka</p>
                    </div>

                    <p className="pmt_context_line">budoucí</p>
                    {nextPayout.amount !== 0 && 
                        <div className='pmt_line_box expected'>
                            <p>{timestampToDate(nextPayout.timestemp)}</p>
                            <p className={nextPayout.acount_move >= 0 ? 'move_up' : 'move_down'}>{nextPayout.acount_move + '%'}</p>
                            <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(nextPayout.amount)}</p>
                        </div>
                    }

                    <p className="pmt_context_line">poslední vyplacené</p>
                    <div className="aux_table_wrapper">
                        {Array.isArray(transactionData) && transactionData.map((data, i) => {
                            return (
                                <div className='pmt_line_box past' key={i}>
                                    <p>{timestampToDate(data.timestemp)}</p>
                                    <p className={data.acount_move >= 0 ? 'move_up' : 'move_down'}>{data.acount_move + '%'}</p>
                                    <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(data.amount)}</p>
                                </div>
                            )
                        })}
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default PaymentCalendar
