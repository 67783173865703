/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import { Link } from 'react-router-dom'
import '../css/app.css'
import './css/ForgotenPassword.css'
import Loading from "../components/Loading";

function ResetPassword(props) {
    const { resetPasswordApi, getError } = useContext(MainContext);
   
    const [isFlaged, setFlaged] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    let url = new URL(window.location.href)
    let token = url.searchParams.get("token")

    useEffect(() => {

    }, [])

    const initialState = {
        userInfo: {
            password: '',
            passwordRepeat: '',
            token: token
        },
        errorMsg: '',
        successMsg: '',
    }
    const [state, setState] = useState(initialState);

    const submitForm = async (event) => {
        event.preventDefault();
        setIsLoading(true)

        // console.log(state.userInfo.password, state.userInfo.passwordRepeat)

        if (state.userInfo.password !== state.userInfo.passwordRepeat) {
            setFlaged(true)
        } else {
            setFlaged(false)

            const data = await resetPasswordApi(state.userInfo);
            if (data.success) {
                setState({
                    ...initialState,
                    successMsg: data.message,
                });
            }
            else {
                console.warn(data)
                alert("Nastala chyba " + data.message)
                getError("Nastala chyba " + data.message)
                setState({
                    ...state,
                    successMsg: '',
                    errorMsg: data.message
                });
            }
        }
        setIsLoading(false)
    }

    // On change the Input Value (username, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });


    }

    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="notification notification--error">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="notification notification--success">{state.successMsg}</div>;
    }

    let login_page_bg = ''
    if (props.login_bg) {
        login_page_bg = 'login_page'
    } else {
        login_page_bg = ''
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    // check user permissions

    return (
        <div className={`_loginRegister ${login_page_bg}`}>
            <div className="register_form_container">
                <Link to="/"> <div className="close_bnt"></div></Link>

                <div className="container content_box cb-small forgoten_pass_container">
                    <h1 className="main_h1">Obnovení zapomenutého hesla</h1>
                    <h2 className="sec_headline">login pro přihlášení je Váš email</h2>
                    <form onSubmit={submitForm} noValidate>

                        <div className='form_inside_reset'>
                            <div className="form-control">
                                <label className="text-gray inp-label">heslo</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="••••••••••••" name="password" className="input" type="password" required value={state.userInfo.password} onChange={onChangeValue} />
                                </div>
                            </div>
                            <div className="form-control">
                                <label className="text-gray inp-label">zopakovat heslo</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="••••••••••••" name="passwordRepeat" className={isFlaged ? "has-error input" : "input"} type="password" required value={state.userInfo.passwordRepeat} onChange={onChangeValue} />
                                </div>
                            </div>
                        </div>

                        <div className="container res_pass_link">
                            <div className="row reg_btns">
                                {/* <Link to="/" ><button className="button button--outlined button--small aligner aligner--centerVertical back_btn">zpět</button></Link> */}

                                <div className="form-control">
                                    <button type="submit" className="button button--primay button--small aligner aligner--centerVertical">Změnit heslo</button>
                                </div>
                            </div>
                        </div>


                    </form>

                </div>
            </div>


            {errorMsg}
            {successMsg}
        </div>
    );
    
}
export default ResetPassword
