/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'

import './css/Transactions.css'
import Loading from "./Loading";


function Transactions() {
    const { systemData, userData, getTableWhereApi, userAccountData} = useContext(MainContext);
    const [transactionData, setTransactionData] = useState([])

    useEffect(() => {
        if (userData.loaded !== false) {
            getTransactionData()
        }
    }, [userData])

    const getTransactionData = async () => {
        let requestTransactionData = await getTableWhereApi('transactions', 'user_id', userData.id, 'int')
        if (Array.isArray(requestTransactionData)) {
            setTransactionData(requestTransactionData)
        }
    }

    const [loading, setLoading] = useState(true)

    useEffect(() => {       // wait for userData to be loaded than run function
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }, [transactionData])


    if (!userData.name || loading) {
        return (<>
            <Loading type="page"/>
        </>)
    }

    return (<>
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage transactions_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Výpis transakcí</h3>
                    <p className="cb_fullpage_sublabel_right">Výpis všech vašich transakcí</p>
                </div>

                <div className="tr_header_grid">
                    <div className="grid_item">
                        <p className="legend">Číslo účtu pro vklady</p>
                        <p className="value">{systemData.deposit_bank_account}</p>
                    </div>
                    <div className="grid_item">
                        <p className="legend">Váš variabilní symbol</p>
                        <p className="value">{userData.id}</p>
                    </div>
                    <div className="grid_item">
                        <p className="legend">Číslo účtu pro výplaty</p>
                        <p className="value">{userData.bank_account}</p>
                    </div>
                </div>

                <div className="main_content_box">
                    <div className="trd_line line_legend">
                        <p>transakce</p>
                        <p>popis</p>
                        <p>datum</p>
                        <p>změna</p>
                        <p>částka</p>
                    </div>

                    <div className="trd_line_box_wrapper">
                        {Array.isArray(transactionData) && transactionData.length !== 0 ? transactionData.map((data, i) => {

                            let transactionType = "neznámá"
                            let transactionTypeClass = ""
                            let timestemp = data.timestemp
                            let transactionDate = data.timestemp !== "" ? timestemp.split("_")[0] : ""

                            if(data.type === "0"){
                                transactionType = "Příchozí platba"
                                transactionTypeClass = "tpye0"
                            } else if (data.type === "1") {
                                transactionType = "Příchozí platba"
                                transactionTypeClass = "tpye1"
                            } else if (data.type === "2") {
                                transactionType = "Odchozí platba"
                                transactionTypeClass = "tpye2"
                            }

                            return(
                                <div className={'trd_line_box ' + transactionTypeClass} key={i}>
                                    <p>{transactionType}</p>
                                    <p>{data.description}</p>
                                    <p>{transactionDate}</p>
                                    <p className={data.acount_move >= 0 ? 'move_up' : 'move_down'}>{data.acount_move + '%'}</p>
                                    <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(data.amount)}</p>
                                </div>
                            )
                                    
                                
                        }) : <p className="empty_label">Nebyly nalezeny žádné transakce.</p>}

                        <br />
                        <br />
                    </div>
            
                    
                </div>

            </div>

            <br />
            <br />
        </div>

    </>)
}

export default Transactions