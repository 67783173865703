import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis} from 'recharts';
import './css/PrevChart.css'
import React, { useContext } from "react"
import { MainContext } from '../contexts/MainContext'

function PrevChart(props) {
    const { userAccountData } = useContext(MainContext);

    const transactions = props.data.history
    const transactionsDates = props.data.dates
    const data = [];

    let accountBalance = 0
    for(let i = 0; i < transactions.length; i++){
        accountBalance += transactions[i]
        let jsonDataItem = {
            name: transactionsDates[i],
            val: accountBalance
        }
        data.push(jsonDataItem)
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{label}</p>
                    <p className="val">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: userAccountData.currency, maximumFractionDigits: 0 }).format(payload[0].value) }</p>
                </div>
            );
        }
        return null;
    };

    return (
      <div className="prev_chart">
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
            width={180}
            height={130}
            data={data}
            margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
            }}
            >
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#334D50" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#334D50" stopOpacity={0}/>
                </linearGradient>
            </defs>
                    <XAxis dataKey="name" axisLine={false} tick={false} />
            <Tooltip content={<CustomTooltip />} />
            {/* <Tooltip formatter={(value) => [new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(value), undefined] } /> */}
                    <Area type="monotone" dataKey="val" stroke="#334D50" fill="url(#colorUv)" strokeWidth={2} />
            </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  
}


export default PrevChart;