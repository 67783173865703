/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import '../css/app.css'
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../contexts/MainContext'

function CmsText() {
    const { rootState, setActualPage, getTableApi, postTableApi } = useContext(MainContext);
    const { isAuth } = rootState;
    let navigate = useNavigate();

    // data -> complete lines from database with changed values
    // changes -> id's of changed lines in table    // more info in MainContext->postTableApi docs
    const initialState = { data: [], changes: [], post_table: 'text_data'}
    const [state, setState] = useState(initialState);
    const [successMsg, setSuccessMsg] = useState('');
    const [unsaved, setUnsaved] = useState(false);
    const [actualLang, setActualLang] = useState('cz');

    if (!isAuth){
        navigate('/')
    }

    const renderTableData = async (lang) => {
        let getLang = ''
        if(typeof lang !== 'undefined' && lang !== 'cz'){
            getLang = '_' + lang
        }
        const getTableData = await getTableApi('text_data' + getLang)
        setState({...state, data: getTableData, post_table: 'text_data' + getLang})
    }

    // runs function only once on load
    useEffect(() => {       
        renderTableData()
        setActualPage('text')
    }, [])

    const updateContent = (e, index) => {
        const data = state.data;
        const changes = state.changes;
        data[index].content = e.target.value;
        if (!changes.includes(data[index].id)){
            changes.push(index);
        }
        setUnsaved(true)
        setState({
            ...state,
            data,
            changes
        });
    };

    const changeLang = (event, lang) => {
        event.preventDefault();
        setActualLang(lang)
        renderTableData(lang)
    };

    // handle submit
    const updateTable = async (event) => {
        event.preventDefault();
        console.log(state)
        const getResponse = await postTableApi(state);
        if (getResponse.success){
            setSuccessMsg(<div className="notification notification--success ">Update sucess</div>)
            setUnsaved(false)
        } else {
            setSuccessMsg(<div className="notification notification--error ">Update error</div>)
        }

        setTimeout(function(){
            setSuccessMsg('')
        }, 5000)
    }
    
    return (
        <>
            <div className="container cms_page_container">
                <h3 className="page_headline">Editace textových dat</h3>

                <div className="cms_page_wraper">
                    <div className="cards_item" key='0'>
                        <div className="container content_box cb-small">
                            {state.data.map((data, i) => {
                                if (data.item_type === 'textarea') {
                                    return (
                                        <>
                                            <label className="text-gray input_label" key={data.id + 'lb'} >{data.item_name}</label>
                                            <div className="textarea" key={data.id}>
                                                <textarea id={data.id} value={data.content}  onChange={e => updateContent(e, i)}>{data.content}</textarea>
                                            </div>
                                        </>
                                    )
                                }
                                return (
                                    <div className="text_edit_item" key={data.id}>
                                        <label className="text-gray input_label">{data.item_name}</label>
                                        <div className="input input-fullWidth">
                                            <input type="text" id={data.id} value={data.content} onChange={e => updateContent(e, i)} />
                                        </div>
                                    </div>

                                );
                            })}
                        </div>
                    </div>
                    <div className="cards_item" key='1'>
                        <div className="cards_item_fixed">

                            <div className={`container content_box cb-small-short action_box_container ${unsaved ? 'highlight_box' : ''}`}>
                                <div className="action_box_wrpaper">
                                    <p className="label">Uložit změny provedené na webu: </p>
                                    <button onClick={updateTable} className="button button--small update_btn">Uložit</button>
                                    {unsaved ? <p className="unsaved_changes">(neuložené změny)</p> : ''}
                                    {successMsg}
                                </div>
                            </div>

                            <div className='container content_box cb-small-short action_box_container long'>
                                <p className="label">Jazykové verze textů webu: </p>
                                <div className="action_box_wrpaper fr_grid">
                                    <button onClick={e => changeLang(e, 'cz')} className={`button button--small update_btn ${actualLang !== 'cz' ? 'button--outlined' : ''}`} >CZ</button>
                                    <button onClick={e => changeLang(e, 'en')} className={`button button--small update_btn ${actualLang !== 'en' ? 'button--outlined' : ''}`} >EN</button>
                                    <button onClick={e => changeLang(e, 'eshop_cz')} className={`button button--small update_btn ${actualLang !== 'eshop_cz' ? 'button--outlined' : ''}`} >eshop CZ</button>
                                    <button onClick={e => changeLang(e, 'eshop_en')} className={`button button--small update_btn ${actualLang !== 'eshop_en' ? 'button--outlined' : ''}`} >eshop EN</button>
                                </div>
                            </div>

                        </div>
                    </div>            
                </div>
            </div>
        </>
    );
}

export default CmsText;