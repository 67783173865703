import React, { useContext } from "react"
import generatePDF from "./services/pdfGenerator"
import { MainContext } from '../contexts/MainContext'

const GeneratePdf = () => {
    const { pageApiUrl, userAccountData, mailApi } = useContext(MainContext);

    console.log(pageApiUrl)

    // const [tickets, setTickets] = useState([])    
    

    // useEffect(() => {
    //     const getAllTickets = async () => {
    //         try {
    //             const response = await Axios.get("http://localhost:3000/tickets")    
    //             setTickets(response.data.tickets)    
    //         } catch (err) {
    //             console.log("error getAllTickets()")    
    //         }
    //     }    
    //     getAllTickets()    
    // }, [])    

    let userData = {
        doc_id: '10236',
        name: 'Jan Novák',
        birth_number: '010532/5343',
        address: 'Na Průhonu 17125, 321 00 Plzeň 21',
        con_address: 'Na Průhonu 17125, 321 00 Plzeň 21',
        tel: '370 253 874',
        email: 'novak.jan@seznam.cz',
        bank_account: '43-74839348244/5540',
        amount: '65 000',
        amount_words: 'šedesát pět tisíc',
        interest: '8',
        duration: '4',
        sms_code: '543554'
    }

    async function senMailApi (){
        console.log("senMailApi()")
        let mailData = {
            mail_to: 'kovarjan@earc.cz',
            subject: 'Informace k výběru - transakce zamítnuta',
            message: ('Dobrý den, <br><br>'
            + 'Váš požadavek na výběr byl zamítnut. <br><br>'
            + 'Transakce byla zamítnuta z důvodu nedostatečných volných prostředků na účtě. <br>'
            + 'Podle našich záznamů transakcí máte momentálně volně k dispozici 0 Kč. <br><br>'
            + 'V případě nejasností kontaktujte svého obchodního poradce. <br><br>'
            + 'Omlouváme se za nepříjemnosti a děkujeme za pochopení. <br><br>'
            + 'S přáním pěkného dne <br>'
            + 'FutureInvest <br>'
            )
        }
        let mailResponse = await mailApi(mailData)

        if (mailResponse.Messages[0].Status !== 'success') {
            console.warn("[ error ] --> mail send" , mailResponse)
            return false
        }
        console.log("[ success ] --> mail send" , mailResponse)
    }
    
    return (
        <>
        
            <button className="button button--small" onClick={() => generatePDF(userData, pageApiUrl, userAccountData.currency)} >
                Generate PDF
            </button>
                    
        
            <button className="button button--small" onClick={() => senMailApi()} >
                send Mail
            </button>
                    
        </>
    )    
}    

export default GeneratePdf    

//            <TicketsComponent tickets={tickets} />
