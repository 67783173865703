import './css/app.css';
import './css/sierra.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Register from './components/Register'
import RegisterLink from './pages/RegisterLink'
import ForgotenPassword from './pages/ForgotenPassword'
import GetRegisterLink from './components/GetRegisterLink'
import MainContextProvider from './contexts/MainContext';

import Home from './pages/Home'
import CmsText from './pages/Cms_text'
import CmsImages from './pages/Cms_images'
import TransactionsPage from './pages/TransactionsPage'
import FaqPage from './pages/FaqPage'
import NewsPage from './pages/NewsPage'
import ClientsPage from './pages/ClientsPage'
import SettingsPage from './pages/SettingsPage'
import ChangePass from './components/ChangePass'
import Error404 from './pages/Error404'
import ResetPassword from './pages/ResetPassword'
import GeneratePdf from './components/GeneratePdf'

function App() {
    console.log("React App build by earc 2022")

    return (
        <MainContextProvider>
            <BrowserRouter>

                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />}>
                        <Route index element={<Home />} />
                    </Route>
                    <Route path='/login' element={<Home />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/get-register-link' element={<GetRegisterLink />} />
                    <Route path='/register-link' element={<RegisterLink />} />
                    <Route path='/forgoten-password' element={<ForgotenPassword />} />
                    <Route path='/reset-password' element={<ResetPassword />} />
                    <Route path='/change_pass' element={<ChangePass />} />

                    <Route path='/text' element={<CmsText />} />
                    <Route path='/images' element={<CmsImages />} />

                    <Route path='/transactions' element={<TransactionsPage />} />
                    <Route path='/faq' element={<FaqPage />} />
                    <Route path='/news' element={<NewsPage />} />
                    <Route path='/clients' element={<ClientsPage />} />
                    <Route path='/settings' element={<SettingsPage />} />

                    <Route path='/test-pdf' element={<GeneratePdf />} />

                    <Route path="*" element={<Error404 />} />

                </Routes>
            </BrowserRouter>
        </MainContextProvider>
    );


}

export default App;


